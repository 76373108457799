import { create } from "zustand";
import { persist } from "zustand/middleware"; // devtools could be imported from this library.
import createThemeSlice, { IThemeSlice } from "./slices/themeSlice";
import createLayoutSlice, { ILayoutSlice } from "./slices/layoutSlice";
import createTicketSlice, { ITicketSlice } from "./slices/ticketSlice";
import createNotificationSlice, {
  INofiticationSlice,
} from "./slices/notificationSlice";

const useBoundStore = create<
  IThemeSlice &
    ILayoutSlice &
    ITicketSlice &
    INofiticationSlice
>()(
  persist(
    (...a) => ({
      ...createThemeSlice(...a),
      ...createLayoutSlice(...a),
      ...createTicketSlice(...a),
      ...createNotificationSlice(...a),
    }),
    {
      name: "eip-store",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !["openTicketDrawer", "platformArea", "ticket"].includes(key)
          )
        ),
    }
  )
);

export default useBoundStore;
