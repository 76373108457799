import React, { memo } from "react";
import Box from "@mui/material/Box";

const DataNotAvailable = ({ desc }: { desc?: string }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <Box color="primary.light" textAlign="center">
      {!!desc ? desc : "No data available"}
    </Box>
  </Box>
);

export default memo(DataNotAvailable);
