import React, { useContext } from "react";
import Box from "@mui/material/Box";
import {
  RevealdMarkIcon,
  EpiphanyLoginLogo,
  GoCyberProductLogoSvg,
  GoCyberMarkIcon,
} from "../../components-v2/icons";
import { EnvironmentContext } from "../../app/utils/EnvironmentProvider";

const TopLogo = ({ expanded }: { expanded: boolean }) => {
  const environment = useContext(EnvironmentContext);
  return expanded ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        pl: 1,
        pt: environment.PRODUCT_LOGO === "GoCyber" ? 3 : 1,
      }}
    >
      {environment.PRODUCT_LOGO === "EPIPHANY" ? (
        <EpiphanyLoginLogo width={220} />
      ) : (
        environment.PRODUCT_LOGO === "GoCyber" && (
          <GoCyberProductLogoSvg width={250} />
        )
      )}
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {environment.PRODUCT_LOGO === "EPIPHANY" ? (
        <RevealdMarkIcon width={26} height={26} />
      ) : (
        environment.PRODUCT_LOGO === "GoCyber" && (
          <GoCyberMarkIcon width={45} height={45} />
        )
      )}
    </Box>
  );
};

export default TopLogo;
