/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useBoundStore from "../../store";
import { lightTheme, darkTheme } from "../../store/slices/themeSlice/themes";
import LinearProgress from "@mui/material/LinearProgress";
import EntryAuthorization from "../pages/auth/entryPage/EntryAuthorization";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import AuthWrapper from "../pages/auth/AuthWrapper";
import { useUserInfo } from "../pages/auth/entryPage/UserInfoContext";
import App from "../../App";
// import EpiphanyReport from "../pages/home/EpiphanyReport";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";

export const Routes = withRouter(({ apolloClient, history }) => {
  const theme = useBoundStore((state) => state.currentTheme);

  const [keycloak, setKeycloak] = useState(null);
  const location = useLocation();
  const userInfo = useUserInfo();
  const element = parseQuery(location.search);
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <CssBaseline />
      <EntryAuthorization setKeycloak={setKeycloak} history={history}>
        {!!keycloak && !!keycloak.init ? (
          <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={(event, error) => {
              if (event === "onReady") {
                userInfo.setAuthInfo({
                  token: keycloak.token,
                  tokenParse: keycloak.tokenParsed,
                  authenticated: keycloak.authenticated,
                });
              }
              if (event === "onAuthRefreshSuccess") {
                userInfo.setAuthInfo({
                  token: keycloak.token,
                  tokenParse: keycloak.tokenParsed,
                  authenticated: keycloak.authenticated,
                });
              }
            }}
          >
            <AuthWrapper keycloak={keycloak}>
              <Switch>
                <Route path="/error" component={ErrorsPage} />
                <Redirect from="/auth" to={element.back || "/new-dashboard"} />
                <Route path="/logout" component={LogoutPage} />
                {/* <Route path="/epiphany-report" component={EpiphanyReport} /> */}
                <App apolloClient={apolloClient} />
              </Switch>
            </AuthWrapper>
          </ReactKeycloakProvider>
        ) : (
          <LinearProgress />
        )}
      </EntryAuthorization>
    </ThemeProvider>
  );
});

function parseQuery(queryString) {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}
