import React, { useCallback } from "react";

export const ColumnHeader = ({ displayName, column, sorting, onSort }) => {
  const onSortAsync = useCallback(() => {
    const sortField =
      column.userProvidedColDef.cellRendererParams?.sortField ||
      column.getColId();
    if (sortField === sorting.orderBy) {
      onSort(sortField, sorting.order === "asc" ? "desc" : "asc");
    } else {
      onSort(sortField, "desc");
    }
  }, [column, sorting, onSort]);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ paddingRight: 4, cursor: "pointer" }} onClick={onSortAsync}>
        {displayName}
      </div>
      {(column.userProvidedColDef.cellRendererParams?.sortField ||
        column.getColId()) === sorting.orderBy && (
        <i
          className={`fa fa-long-arrow-alt-${
            sorting.order === "asc" ? "down" : "up"
          }`}
        />
      )}
    </div>
  );
};
