import * as d3 from "d3";
export const getRadius = (counts = 0) => Math.sqrt(counts) * (counts < 5 ? 0.6 : 1);
export const zoom = d3.zoom();

export const getEiIconsize = (linkCounts) => {
  if (linkCounts <= 5) {
    return "ei-lg";
  } else if (linkCounts <= 50) {
    return "ei-2x";
  } else if (linkCounts <= 100) {
    return "ei-3x";
  } else if (linkCounts <= 500) {
    return "ei-4x";
  } else return "ei-5x";
};

export const patternDistance = (counts, index, dis) => {
  let deeps = Math.ceil(counts / 20);
  return dis / ((index % deeps) + 1);
};
export const distance = (x1, y1, x2, y2) =>
  Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));

export const maxCountsOfLevel = (nodes) =>
  Math.max(
    nodes.filter((n) => n.level === 0).length,
    nodes.filter((n) => n.level === 1).length,
    nodes.filter((n) => n.level === 2).length,
    nodes.filter((n) => n.level === 3).length
  );

export const getLinks = (nodes, links) =>
  links.map((link) => {
    let source = nodes.find(({ id }) => id === link.node1);
    let target = nodes.find(({ id }) => id === link.node2);
    let tmpNode = null;
    if (source.level < target.level) {
      tmpNode = source;
      source = target;
      target = tmpNode;
    }
    return {
      ...link,
      node1: source.id,
      node2: target.id,
      source: source,
      target: target,
    };
  });

export const center = (angle, distance) => {
  return {
    cx: distance * Math.cos(angle * 2),
    cy: distance * Math.sin(angle * 2),
  };
};

export const endPoints = (nodes, links, config) => {
  let withoutLastRing = links.filter(
    (d) =>
      d.source.level !== config.levelCounts - 1 &&
      d.target.level !== config.levelCounts - 1
  );

  return nodes.filter(
    (node) =>
      withoutLastRing.filter(
        (link) => link.node1 === node.id || link.node2 === node.id
      ).length === 1 || node.level === 3
  );
};
