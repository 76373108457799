export const randomInRange = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min)) + min;

export const svgToBase64 = (el: SVGSVGElement) => {
  // Serialize the SVG element to an XML string
  const svgXml = new XMLSerializer().serializeToString(el);

  // Create a data URL with the MIME type and base64-encoded SVG
  const dataUrl = `data:image/svg+xml;base64,${btoa(
    unescape(encodeURIComponent(svgXml))
  )}`;
  return dataUrl;
};

export const groupBy = (
  arr: Record<string, any>[],
  keyGetter: (element: Record<string, any>) => string
) => {
  const map = new Map();
  arr.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Object.fromEntries(map);
};
