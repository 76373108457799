import { StateCreator } from "zustand";
import {
  ApolloClient,
  NormalizedCacheObject,
  ApolloQueryResult,
} from "@apollo/client";
import { GET_ALL_USERS_BASIC } from "../../../app/queries";
import {
  GET_TICKET_STATUSES,
  GET_TICKET_PRIORITIES,
  GET_TICKET_TYPES,
  GET_PLATFORM_AREAS,
  GET_TICKET_RESOLUTIONS,
  GET_TICKETS,
  GET_WIDGET_CONTENT,
} from "../../../app/pages/home/ticketcenter/queries";
import { Ticket } from "./models";
import { UserInternalSchema } from "../../../app/pages/home/UserSettings/Models/SharedModels";
import { convertResponseToArray, response2info } from "./helpers";
import { convertRawTicket } from "../../../app/pages/home/ticketcenter/helpers";

export interface ITicketSlice {
  loading: boolean;
  loadingUsers: boolean;
  loadingTicketStatus: boolean;
  loadingTicketPriorities: boolean;
  loadingTicketTypes: boolean;
  loadingTicketResolutions: boolean;
  loadingPlatformAreas: boolean;
  loadingWidgets: boolean;
  currentUser?: UserInternalSchema;
  ticketDownloadToken: string;
  isMyTicketOnly?: boolean;
  totalPages: number;
  totalResult: number;
  tickets: Ticket[];
  widgets: {
    title: string;
    tooltip: string;
    data: any;
    total?: number;
  }[];
  oldTicketsFetchVariables?: Record<string, any>;
  userDict: Record<string, { email: string; id: string; name: string }>;
  ticketStatuses: {
    name: string;
    value: number;
    color?: string;
    search_name: string;
  }[];
  ticketPriorities: {
    name: string;
    value: number;
    color?: string;
    search_name: string;
  }[];
  ticketTypes: {
    name: string;
    value: number;
    color?: string;
    search_name: string;
  }[];
  ticketResolutions: {
    name: string;
    value: number;
    color?: string;
    search_name: string;
  }[];
  platformAreas: {
    name: string;
    value: number;
    color?: string;
    search_name: string;
  }[];

  openTicketDrawer: boolean;

  setCurrentUser: (user: UserInternalSchema) => void;
  setMyTicketOnly: (newValue: boolean) => void;

  // get platformare number for the page
  getPlatformAreaValue: (page: string) => number;

  fetchUserDict: (client: ApolloClient<NormalizedCacheObject>) => Promise<void>;
  fetchTickets: (
    client: ApolloClient<NormalizedCacheObject>,
    variables?: Record<string, any>,
    myTicketsOnly?: boolean
  ) => Promise<{ tickets: Ticket[]; totalResult: number }>;
  fetchWidgets: (
    client: ApolloClient<NormalizedCacheObject>,
    widgetIds: string[]
  ) => Promise<void>;
  fetchTicketStatuses: (
    client: ApolloClient<NormalizedCacheObject>
  ) => Promise<void>;
  fetchPlatformAreas: (
    client: ApolloClient<NormalizedCacheObject>
  ) => Promise<void>;
  fetchTicketPriorities: (
    client: ApolloClient<NormalizedCacheObject>
  ) => Promise<void>;
  fetchTicketTypes: (
    client: ApolloClient<NormalizedCacheObject>
  ) => Promise<void>;
  fetchTicketResolutions: (
    client: ApolloClient<NormalizedCacheObject>
  ) => Promise<void>;
}

const createTicketSlice: StateCreator<ITicketSlice, [], [], ITicketSlice> = (
  set,
  get
) => ({
  loading: false,
  loadingUsers: false,
  loadingTicketStatus: false,
  loadingTicketPriorities: false,
  loadingTicketTypes: false,
  loadingTicketResolutions: false,
  loadingPlatformAreas: false,
  loadingWidgets: false,
  openTicketDrawer: false,
  platformArea: 0,
  ticket: undefined,
  oldTicketsFetchVariables: undefined,
  totalPages: 0,
  totalResult: 0,
  tickets: [],
  ticketDownloadToken: "",
  widgets: [],
  currentUser: undefined,
  isMyTicketOnly: false,

  userDict: {},
  ticketStatuses: [],
  ticketPriorities: [],
  ticketTypes: [],
  ticketResolutions: [],
  platformAreas: [],

  getPlatformAreaValue: (page: string) =>
    get().platformAreas.find(({ name }) => name === page)!.value,

  setMyTicketOnly: (newValue) => set({ isMyTicketOnly: newValue }),

  fetchUserDict: async (client) => {
    // const activeUser = get().currentUser;
    // if (
    //   activeUser?.rights &&
    //   ["eip_sr_0xFFF", "eip_sr_0x000", "eip_sr_0x016", "eip_sr_0x010"].every(
    //     (preDefinedRight) =>
    //       (activeUser?.rights || []).includes(preDefinedRight)
    //   )
    // ) {
    set({ loadingUsers: true });
    try {
      const response = await client.query({
        query: GET_ALL_USERS_BASIC,
        variables: {
          vars: "",
        },
        fetchPolicy: "cache-first",
      });
      if (response.data && response.data.listAllUsersBasicInfo) {
        const dict = response.data.listAllUsersBasicInfo.reduce((pv, user) => {
          pv[user.id] = {
            id: user.id,
            name: `${user.firstName || ""} ${user.lastName || ""}`,
            email: user.email,
          };
          return pv;
        }, {});
        set({ userDict: dict, loadingUsers: false });
      } else {
        set({ loadingUsers: false, userDict: {} });
      }
    } catch (e) {
      console.error("Error in getAllUsers: ", e);
      set({ loadingUsers: false, userDict: {} });
    }
    // }
  },

  setCurrentUser: (user) =>
    set({ currentUser: { ...get().currentUser, ...user } }),
  fetchTickets: async (client, variables) => {
    set({ loading: true });
    try {
      const response = await client.query({
        query: GET_TICKETS,
        fetchPolicy: "network-only",
        variables: {
          ...(!!variables ? variables : get().oldTicketsFetchVariables),
          assignedTo: get().isMyTicketOnly ? get().currentUser?.id : "",
        },
      });
      const result = convertResponseToArray({
        response: response.data,
        dataField: "getTickets",
        hasPagination: true,
        convertFn: convertRawTicket,
      });
      if (!result.hasError) {
        set({
          ticketDownloadToken: result.token,
          totalPages: result.totalPages,
          totalResult: result.totalResults,
          tickets: result.records,
          loading: false,
          ...(!!variables && { oldTicketsFetchVariables: variables }),
        });
        return {
          tickets: result.records,
          totalResult: result.totalResults || 0,
        };
      } else {
        set({ loading: false, tickets: [] });
        return { tickets: [], totalResult: 0 };
      }
    } catch (e) {
      console.error("Error in getTickets: ", e);
      set({ loading: false, tickets: [] });
      return { tickets: [], totalResult: 0 };
    }
  },
  // this function is using in Ticket and Agent both
  fetchWidgets: async (client, widgetIds: string[]) => {
    set({ loadingWidgets: true, widgets: [] });
    try {
      const responses: Promise<ApolloQueryResult<any>>[] = [];
      for (const widgetId of widgetIds) {
        const response = client.query({
          query: GET_WIDGET_CONTENT,
          fetchPolicy: "network-only",
          variables: {
            widgetsGetWidgetType: widgetId,
          },
        });
        responses.push(response);
      }
      const results = await Promise.allSettled(responses);

      const convertedResult = results.map((result) => response2info(result));
      set({ loadingWidgets: false, widgets: convertedResult });
    } catch (e) {
      console.error("Error in getTicketWidgets: ", e);
      set({ loadingWidgets: false, widgets: [] });
    }
  },
  fetchTicketStatuses: async (client) => {
    // const activeUser = get().currentUser;
    // if (
    //   activeUser?.rights &&
    //   ["eip_sr_0x014", "eip_sr_0xFFF", "eip_sr_0x000"].every(
    //     (preDefinedRight) =>
    //       (activeUser?.rights || []).includes(preDefinedRight)
    //   )
    // ) {
    set({ loadingTicketStatus: true });
    try {
      const response = await client.query({
        query: GET_TICKET_STATUSES,
      });
      const result = convertResponseToArray({
        response: response.data,
        dataField: "getTicketStatuses",
        subField: "statuses",
      });
      if (!result.hasError) {
        set({
          ticketStatuses: result.records as any,
          loadingTicketStatus: false,
        });
      } else {
        set({ loadingTicketStatus: false });
      }
    } catch (e) {
      console.error("Error in getTicketStatuese: ", e);
      set({ loadingTicketStatus: false });
    }
    // }
  },
  fetchPlatformAreas: async (client) => {
    // const activeUser = get().currentUser;
    // if (
    //   activeUser?.rights &&
    //   ["eip_sr_0x014", "eip_sr_0xFFF", "eip_sr_0x000"].every(
    //     (preDefinedRight) =>
    //       (activeUser?.rights || []).includes(preDefinedRight)
    //   )
    // ) {
    set({ loadingPlatformAreas: true });
    try {
      const response = await client.query({
        query: GET_PLATFORM_AREAS,
      });
      const result = convertResponseToArray({
        response: response.data,
        dataField: "getTicketPlatformAreas",
        subField: "platformAreas",
      });
      if (!result.hasError) {
        set({
          platformAreas: result.records as any,
          loadingPlatformAreas: false,
        });
      } else set({ loadingPlatformAreas: false });
    } catch (e) {
      console.error("Error in getTicketPlatformAreas: ", e);
      set({ loadingPlatformAreas: false });
    }
    // }
  },
  fetchTicketPriorities: async (client) => {
    // const activeUser = get().currentUser;
    // if (
    //   activeUser?.rights &&
    //   ["eip_sr_0x014", "eip_sr_0xFFF", "eip_sr_0x000"].every(
    //     (preDefinedRight) =>
    //       (activeUser?.rights || []).includes(preDefinedRight)
    //   )
    // ) {
    set({ loadingTicketPriorities: true });
    try {
      const response = await client.query({
        query: GET_TICKET_PRIORITIES,
      });
      const result = convertResponseToArray({
        response: response.data,
        dataField: "getTicketPriorities",
        subField: "priorities",
      });

      if (!result.hasError) {
        set({
          ticketPriorities: result.records as any,
          loadingTicketPriorities: false,
        });
      } else set({ loadingTicketPriorities: false });
    } catch (e) {
      console.error("Error in getTicketPriorities: ", e);
      set({ loadingTicketPriorities: false });
    }
    // }
  },
  fetchTicketTypes: async (client) => {
    // const activeUser = get().currentUser;
    // if (
    //   activeUser?.rights &&
    //   ["eip_sr_0x014", "eip_sr_0xFFF", "eip_sr_0x000"].every(
    //     (preDefinedRight) =>
    //       (activeUser?.rights || []).includes(preDefinedRight)
    //   )
    // ) {
    set({ loadingTicketTypes: true });
    try {
      const response = await client.query({
        query: GET_TICKET_TYPES,
      });
      const result = convertResponseToArray({
        response: response.data,
        dataField: "getTicketTypes",
        subField: "types",
      });
      if (!result.hasError) {
        set({ ticketTypes: result.records as any, loadingTicketTypes: false });
      } else set({ loadingTicketTypes: false });
    } catch (e) {
      console.error("Error in getTicketTypes: ", e);
      set({ loadingTicketTypes: false });
    }
    // }
  },
  fetchTicketResolutions: async (client) => {
    // const activeUser = get().currentUser;
    // if (
    //   activeUser?.rights &&
    //   ["eip_sr_0x014", "eip_sr_0xFFF", "eip_sr_0x000"].every(
    //     (preDefinedRight) =>
    //       (activeUser?.rights || []).includes(preDefinedRight)
    //   )
    // ) {
    set({ loadingTicketResolutions: true });
    try {
      const response = await client.query({
        query: GET_TICKET_RESOLUTIONS,
      });
      const result = convertResponseToArray({
        response: response.data,
        dataField: "getTicketResolutions",
        subField: "resolutions",
      });
      if (!result.hasError) {
        set({
          ticketResolutions: result.records as any,
          loadingTicketResolutions: false,
        });
      }
      set({ loadingTicketResolutions: false });
    } catch (e) {
      console.error("Error in getTicketResolutions: ", e);
      set({ loadingTicketResolutions: false });
    }
    // }
  },
});

export default createTicketSlice;
