import { styled } from "@mui/material/styles";
import layoutConfig from "../layout-config.json"

export interface IDrawerHeader {
  expanded?: boolean;
}

const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "expanded",
})<IDrawerHeader>(({ theme, expanded }) => ({
  display: "flex",
  alignItems: "center",
  height: expanded ? layoutConfig.extendedLogoHeight : layoutConfig.topNavbarHeight,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  background: theme.palette.primary.main,
}));

export default DrawerHeader;
