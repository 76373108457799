import React, { useState, useEffect, useCallback } from "react";
import { ColumnApi, Column } from "ag-grid-community";
import { useDebounce } from "use-debounce";
import {
  Box,
  Divider,
  Popover,
  MenuItem,
  ButtonGroup,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CSVDownloadButton from "./CSVDownloadButton";
import { SearchIcon } from "../../icons";
import XInput from "../../base-ui/XInput";

export const InfiniteHeaderPanel = ({
  initialSearchText,
  gridColumnApi,
  token,
  disableColumnsSetting,
  onSearch,
}: {
  initialSearchText?: string;
  disableColumnsSetting?: boolean;
  gridColumnApi: ColumnApi;
  token?: string;
  onSearch: (searchText: string) => void;
}) => {
  const [selectedColumn, setSelectedColumn] = useState<Column | null>();
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [columnSelectionAnchorEl, setColumnSelectionAnchorEl] =
    useState<null | HTMLElement>(null);

  const [searchText, setSearchText] = useState(initialSearchText || "");
  const [debouncedSearchText] = useDebounce(searchText, 1500);

  const [, forceRender] = useState<number>(0);

  const onColumnItem = useCallback((column?: Column) => {
    setFilterAnchorEl(null);
    if (!column) {
      // Advanced filter
      setSelectedColumn(null);
    } else {
      setSelectedColumn(column);
    }
  }, []);

  const onChangeColumnSelection = useCallback(
    (column: Column) => {
      gridColumnApi.setColumnVisible(column.getColId(), !column.isVisible());
      forceRender(Math.random());
    },
    [gridColumnApi]
  );

  const onFilterToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setFilterAnchorEl(event.currentTarget);
    },
    []
  );

  const onColumnSelectionToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setColumnSelectionAnchorEl(event.currentTarget);
    },
    []
  );

  useEffect(() => {
    setSelectedColumn(null);
    setSearchText(initialSearchText || "");
  }, [initialSearchText]);

  useEffect(() => {
    if (selectedColumn) {
      onSearch(selectedColumn?.getColId() + ":" + (debouncedSearchText || "*"));
    } else {
      onSearch(debouncedSearchText);
    }
  }, [onSearch, selectedColumn, debouncedSearchText]);

  return (
    <div>
      <ButtonGroup
        size="small"
        variant="contained"
        sx={{
          zIndex: 2,
          border: "1px solid",
          borderColor: "rgba(255, 255, 255, 0.25)",
        }}
      >
        <Button size="small" aria-haspopup="menu" onClick={onFilterToggle}>
          <ArrowDropDownIcon />
        </Button>
        {!!selectedColumn && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              px: 2,
              fontWeight: "normal",
            }}
          >
            {selectedColumn?.getColDef().headerName}
          </Box>
        )}
        <XInput
          placeholder="Search"
          size="small"
          sxProps={{
            m: 2,
            bgcolor: "primary.dark",
            border: "none",
            margin: 0,
            minWidth: 400,
            borderRadius: 0,
          }}
          startAdornment={<SearchIcon />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          clearable
          onClear={() => setSearchText("")}
        />
        <Button
          size="small"
          onClick={onColumnSelectionToggle}
          disabled={disableColumnsSetting}
        >
          <i className="fa-light fa-gear fa-lg"></i>
        </Button>

        <CSVDownloadButton token={token} />
        <Button
          size="small"
          target="_blank"
          href="https://docs.epiphanysys.com/technical-documentation/admin-guides/epiphany-intelligence-platform-administrator-guide/search-and-query-guidelines/search-basics"
        >
          <i className="fa-regular fa-circle-question fa-lg"></i>
        </Button>
      </ButtonGroup>
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem sx={{ fontSize: 12 }} onClick={() => onColumnItem()}>
          Free Search
        </MenuItem>
        <Divider sx={{ my: 0 }} />
        {(gridColumnApi?.getColumns() || [])
          .filter((col) => !!col.getColDef().headerName)
          .filter((col) => col.isVisible())
          .map((col: Column) => (
            <MenuItem
              key={col.getColId()}
              sx={{ fontSize: 12 }}
              onClick={() => onColumnItem(col)}
            >
              {col.getColDef().headerName}
            </MenuItem>
          ))}
      </Popover>
      <Popover
        open={Boolean(columnSelectionAnchorEl)}
        anchorEl={columnSelectionAnchorEl}
        onClose={() => setColumnSelectionAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {(gridColumnApi?.getColumns() || [])
          .filter((col) => !!col.getColDef().headerName)
          .map((col) => (
            <MenuItem
              key={col.getColId()}
              sx={{ fontSize: 12 }}
              onClick={() => onChangeColumnSelection(col)}
            >
              {col.isVisible() ? (
                <CheckBoxIcon sx={{ fontSize: 18, mr: 2 }} />
              ) : (
                <CheckBoxOutlineBlankIcon sx={{ fontSize: 18, mr: 2 }} />
              )}{" "}
              {col.getColDef().headerName}
            </MenuItem>
          ))}
      </Popover>
    </div>
  );
};
