import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const LoadingOverlay = ({
  loadingMessage = "",
}: {
  loadingMessage?: string;
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9,
        background: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <CircularProgress color="info" />
      {loadingMessage}
    </Box>
  );
};
