import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useMe } from "../../app/pages/auth/LoginAuth";

const DYNAMIC_DASHBOARDS_TITLE = "Dashboards";

function parsePathname(path: string) {
  const splitedPath = path.split("/");
  return {
    pathname: path,
    path: splitedPath[1] || "",
    id: splitedPath[2] || "",
    name: splitedPath[3] || "",
  };
}

function replaceAndRemoveId(items, exPages) {
  const dynamicPages = exPages?.find(
    (ep) => ep.title === DYNAMIC_DASHBOARDS_TITLE
  );
  items.forEach((item) => {
    if (item.pageid) {
      items = items.map((t) => (t?.pageid === item.pageid ? item : t));
      if (dynamicPages) {
        if (
          !dynamicPages.submenu.map((sm) => sm.pageid).includes(item.pageid)
        ) {
          //deleted dynamic page
          items = items.filter((t) => t?.pageid !== item.pageid);
        }
      } else {
        items = items.filter((t) => !t.pageid);
      }
    }
  });

  if (
    items.length > 1 &&
    items[items.length - 1].page === items[items.length - 2].page
  ) {
    items = items.filter((_, k) => k < items.length - 1);
  }

  return items;
}

function safePagesIterator(items) {
  if (items && items.length > 0) {
    return [...items];
  }
  return [];
}

export default function Breadcrumb() {
  const user: any = useMe();
  const { pathname } = useLocation();
  const [historyPages, setHistoryPages] = useState<any>([]);

  useEffect(() => {
    if (pathname && user && user.parsedMenuOptions) {
      const existingPages = [
        ...safePagesIterator(user.parsedMenuOptions.aside?.items),
        ...safePagesIterator(user.parsedMenuOptions.admin?.items),
      ];
      if (!existingPages || existingPages.length === 0) return;

      const matchedSection = existingPages.find((section) =>
        (section.submenu || []).map((sm) => "/" + sm.page).includes(pathname)
      );

      let matchedPage;

      if (matchedSection) {
        matchedPage = matchedSection.submenu.find(
          (sm) => "/" + sm.page === pathname
        );
      }
      const pageDetail = parsePathname(pathname);
      
      if (!matchedPage) {
        if (pageDetail.path === "riskexplorer") {
          matchedPage = {
            title: "Risk Explorer",
            page: "riskexplorer",
          };
        }
        if (pageDetail.path === "nodedetail") {
          matchedPage = {
            title: "Device Detail",
            page: "nodedetail",
          };
        }
        if (pageDetail.path === "scout-report") {
          const matched = existingPages.find((section) =>
            (section.submenu || []).map((sm) => sm.page).includes(pageDetail.id)
          );
          matchedPage = matched.submenu.find((sm) => sm.page === pageDetail.id);
        }
      }
      if (!matchedPage) return;

      const match = { ...matchedPage, pathInfo: pageDetail };
      setHistoryPages((prev: any) => {
        let newHistory: any = [];
        if (prev.length > 0) {
          if (prev.length === 4) {
            newHistory = [...prev.slice(1), match];
          }
          if (prev.length < 4) {
            newHistory = [...prev, match];
          }
        } else {
          newHistory = [...prev, match];
        }
        //just for dynamic page
        newHistory = replaceAndRemoveId(newHistory, existingPages);
        return newHistory;
      });
    }
  }, [pathname, user]);

  if (!historyPages || historyPages.length === 0) return <></>;

  return (
    <div>
      {historyPages.length && (
        <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
          {historyPages[historyPages.length - 1].title}
        </Box>
      )}
      {/* <ol className="breadcrumb">
        {historyPages.map((page, k) => (
          <li key={k} className="breadcrumb-item">
            <Link to={page.pathInfo?.pathname}>
              {page.title}
              {page.pathInfo?.name && `(${page.pathInfo.name})`}
            </Link>
          </li>
        ))}
      </ol> */}
    </div>
  );
}
