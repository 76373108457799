import { StateCreator } from "zustand";

type TLayoutConfig = {
  siderExpanded: boolean;
}

export interface ILayoutSlice {
  config: TLayoutConfig;
  updateConfig: () => void;
}

const createLayoutSlice: StateCreator<ILayoutSlice, [], [], ILayoutSlice> = (
  set
) => ({
  config: {
    siderExpanded: true,
  },
  updateConfig: () =>
    set((state) => ({
      config: {
        siderExpanded: !state.config.siderExpanded,
      }
    })),
});

export default createLayoutSlice;
