import moment from "moment";
import { Ticket } from "../../../../store/slices/ticketSlice/models";

export const convertRawTicket = (rawTicket: any): Ticket => {
  return {
    ...(rawTicket.assigned_to &&
      rawTicket.assigned_to !== "None" && {
        assigned_to: rawTicket.assigned_to,
      }),
    ...(rawTicket.cc && rawTicket.cc !== "None" && { cc: rawTicket.cc }),
    ...(rawTicket.changed &&
      rawTicket.changed !== "None" && {
        changed: moment(rawTicket.changed).format("YYYY-MM-DD hh:mm"),
      }),
    ...(rawTicket.created_at &&
      rawTicket.created_at !== "None" && {
        created_at: moment(rawTicket.created_at).format("YYYY-MM-DD hh:mm"),
      }),
    ...(rawTicket.creator &&
      rawTicket.creator !== "None" && { creator: rawTicket.creator }),
    ...(rawTicket.dashboard_id &&
      rawTicket.dashboard_id !== "None" && {
        dashboard_id: rawTicket.dashboard_id,
      }),
    ...(rawTicket.due_date &&
      rawTicket.due_date !== "None" && { due_date: rawTicket.due_date }),
    ...(rawTicket.jira_id &&
      rawTicket.jira_id !== "None" && { jira_id: rawTicket.jira_id }),
    ...(rawTicket.jira_last_sync &&
      rawTicket.jira_last_sync !== "None" && {
        jira_last_sync: rawTicket.jira_last_sync,
      }),
    ...(rawTicket.jira_url &&
      rawTicket.jira_url !== "None" && { jira_url: rawTicket.jira_url }),
    ...(rawTicket.network_id &&
      rawTicket.network_id !== "None" && { network_id: rawTicket.network_id }),
    ...(rawTicket.reporter &&
      rawTicket.reporter !== "None" && { reporter: rawTicket.reporter }),
    ...(rawTicket.start_date &&
      rawTicket.start_date !== "None" && { start_date: rawTicket.start_date }),
    ...(rawTicket.resolution &&
      rawTicket.resolution !== "None" && { resolution: rawTicket.resolution }),
    description: rawTicket.description,
    human_id: rawTicket.human_id,
    id: rawTicket.id,
    title: rawTicket.title,
    platform_area: Number(rawTicket.platform_area),
    priority: Number(rawTicket.priority),
    status: Number(rawTicket.status),
    ticket_type: Number(rawTicket.ticket_type),
  };
};

export const utcDateToLocaleDate = (
  utcDateTime: string,
  onlyYYYYMMDD?: boolean
) => {
  const date = new Date(utcDateTime);
  if (onlyYYYYMMDD) {
    return date.toLocaleDateString();
  }
  return date.toLocaleString();
};

// date: YYYY-MM-DD
export const strToLocalDate = (date: string) => {
  return new Date(date).toISOString().replace("T", " ").replace("Z", "");
};

export const objarr2dict = (
  arr: { name: string; value: number }[] | null | undefined
): Record<string, number> | null => {
  if (!arr || !arr?.length) return null;
  return arr.reduce(
    (a: Record<string, number>, c: { name: string; value: number }) => {
      a[c.name] = c.value;
      return a;
    },
    {}
  );
};

export const correctMultiValues = (
  arr: { name: string; value: Record<string, number> }[] | null | undefined
): { name: string; values: Record<string, number> }[] | null => {
  if (!arr || !arr?.length) return null;
  const availableFields: string[] = [];
  for (const { value } of arr) {
    availableFields.push(...Object.keys(value));
  }
  const uniqueFields = [...new Set(availableFields)];
  return arr.map((item) => {
    const values = uniqueFields.reduce((a, c) => {
      a[c] = item.value[c] || 0;
      return a;
    }, {});

    return {
      name: item.name,
      values,
    };
  });
};

export const correctValue = (
  arr: { name: string; value: number }[] | null | undefined,
  label: string
): { name: string; values: Record<string, number> }[] | null => {
  if (!arr || !arr?.length) return null;
  return arr.map((item) => ({
    name: item.name,
    values: { [label]: item.value },
  }));
};

export const ticketColoringFn = (
  arr: { name: string; value: number; color?: string }[]
): Record<string, string> => {
  return arr.reduce((cv, pv) => {
    cv[pv.name.toLowerCase()] = pv.color || "";
    return cv;
  }, {});
};

export const replaceIds = (
  userDict: Record<string, any>,
  str: string,
  regex: RegExp
) => {
  return str.replace(regex, (_, userId) =>
    userDict[userId] ? userDict[userId].name : "None"
  );
};

export const findItem = (
  arr: { name: string; value: number; color?: string }[],
  search: string | number,
  searchBy: "value" | "name",
  returnAs: "name" | "value" | "color"
): string | number | "" => {
  const foundItem = arr.find((item) =>
    searchBy === "value" ? item.value === search : item.name === search
  );
  return returnAs === "name"
    ? foundItem?.name || ""
    : returnAs === "value"
    ? foundItem?.value || ""
    : foundItem?.color || "";
};
