import React from "react";
import { Box } from "@mui/material";

export const BallChipCell = ({
  value,
  valueFn,
  ballColorFn,
}: {
  value: string;
  valueFn?: (value: string) => string;
  ballColorFn: (value: string) => string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "secondary.light",
          color: "black",
          borderRadius: 4,
          height: 24,
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "fit-content",
          px: 2,
        }}
      >
        <i
          className="fa-solid fa-circle"
          style={{ color: ballColorFn(value) }}
        />
        {valueFn ? valueFn(value) : value}
      </Box>
    </Box>
  );
};
