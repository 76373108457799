import React from "react";
import Box from "@mui/material/Box";
import TopNavbar from "./TopNavbar/TopNavbar";
import { Sider, DrawerHeader } from "./Sider";
import useBoundStore from "../store";
import layoutConfig from "./layout-config.json"

const Layout = ({ children }) => {
  const { siderExpanded } = useBoundStore((state) => state.config);

  return (
    <Box sx={{ display: "flex", bgcolor: "primary.dark", height: 1 }}>
      <TopNavbar open={siderExpanded} />
      <Sider />
      <Box
        component="main"
        sx={{
          height: 1,
          width: `calc(100% - ${
            siderExpanded ? layoutConfig.drawerWidth : layoutConfig.condensedDrawerWidth
          }px)`,
        }}
      >
        <DrawerHeader />
        <Box
          sx={{
            p: 3,
            width: "100%",
            height: `calc(100% - ${layoutConfig.topNavbarHeight}px)`,
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        className="tooltip-area"
        sx={{
          zIndex: 99999,
          pointerEvents: "none",
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Box
          className="app-tooltip"
          sx={{
            display: "none",
            position: "absolute",
            color: "primary.contrastText",
            minWidth: 80,
          }}
        />
      </Box>
    </Box>
  );
};

export default Layout;
