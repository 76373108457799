import React from "react";
import { useTheme, Box } from "@mui/material";

export const CHMLCell = ({ value }: { value: string }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: theme.palette.impact[(value || "").toLowerCase()],
          borderRadius: 4,
          height: 24,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "fit-content",
          px: 2,
          fontWeight: "bold",
        }}
      >
        {value}
      </Box>
    </Box>
  );
};
