import { LinearProgress } from "@mui/material";
import { lazy } from "react";

const ActiveDirectoryPage = lazy(() =>
  import("../app/pages/home/ActiveDirectoryPage")
);
const PathFinderPage = lazy(() =>
  import("../app/pages/home/PathFinderPage/index")
);
const Tactical = lazy(() => import("../app/pages/home/Tactical"));

const ImpactMatrixPage = lazy(() =>
  import("../app/pages/home/ImpactMatrixPage")
);
const RiskExplorer = lazy(() => import("../app/pages/home/RiskExplorer"));
const NodeDetail = lazy(() => import("../app/pages/home/NodeDetailPage"));

const DynamicDashboard = lazy(() =>
  import("../app/pages/home/DynamicDashboard")
);

const Hosts = lazy(() => import("../app/pages/home/hosts"));
const RogueReportPage = lazy(() => import("../app/pages/home/RogueReportPage"));

const DataSourceConfiguration = lazy(() =>
  import("../app/pages/home/configuration/DataSourceConfiguration")
);

// const ProvidersConfiguration = lazy(() =>
//   import("../app/pages/home/providers/ProvidersConfiguration")
// );

const UserManagementPage = lazy(() =>
  import("../app/pages/home/UserSettings/UserManagement")
);
const RolesAdminPage = lazy(() =>
  import("../app/pages/home/UserSettings/Roles/RolesAdminPage")
);
const GroupsAdminPage = lazy(() =>
  import("../app/pages/home/UserSettings/Groups/GroupsAdminPage")
);
const TicketCenter = lazy(() =>
  import("../app/pages/home/ticketcenter/TicketCenter")
);

const EaglePage = lazy(() => import("../app/pages/home/EaglePage/EaglePage"));

const DocumentsPage = lazy(() => import("../app/pages/home/Documents"));

const ScoutToolsPage = lazy(() => import("../app/pages/home/ScoutPage"));

const ScoutReportPage = lazy(() => import("../app/pages/home/ScoutReportPage"));

const routes = [
  {
    path: "/loading",
    isNotGuarded: true,
    component: LinearProgress,
  },
  {
    path: "/documents",
    component: DocumentsPage,
  },
  {
    path: "/vulnerabilities/:cveName?",
    component: Tactical,
  },
  {
    path: "/roguereport",
    component: RogueReportPage,
  },
  {
    path: "/inventory/:pageNo?",
    component: Hosts,
  },
  {
    path: "/pathfinder/:pageNo?",
    component: PathFinderPage,
  },
  {
    path: "/active-directory",
    component: ActiveDirectoryPage,
  },
  {
    path: "/ticketcenter",
    component: TicketCenter,
  },
  {
    path: "/useradmin",
    component: UserManagementPage,
  },
  {
    path: "/rolesadmin",
    component: RolesAdminPage,
  },
  {
    path: "/groupsadmin",
    component: GroupsAdminPage,
  },
  {
    path: "/matrix",
    component: ImpactMatrixPage,
  },
  {
    path: "/riskexplorer/:paramID?/:paramName?/:infoType?",
    component: RiskExplorer,
  },
  {
    path: "/nodedetail/:paramID?/:paramName?",
    component: NodeDetail,
  },
  {
    path: "/new-dashboard",
    component: DynamicDashboard,
  },
  {
    path: "/dynamic/:pageId/:paramName?",
    component: DynamicDashboard,
  },
  {
    path: "/hosts",
    component: Hosts,
  },
  {
    path: "/config/:anchor?",
    component: DataSourceConfiguration,
  },
  {
    path: "/eagle",
    component: EaglePage,
  },
  {
    path: "/new-scout",
    component: ScoutToolsPage,
  },
  { path: "/scout-report/:pageId", component: ScoutReportPage },
  // {
  //   path: "/providers/:anchor?",
  //   component: ProvidersConfiguration,
  // },
];

export default routes;
