import { createPersistedState } from "@plq/use-persisted-state";
import React, { createContext, useContext } from "react";
import storage from "@plq/use-persisted-state/lib/storages/local-storage";
export interface UserInfo {
  userInfo;
  setUserInfo;
  authInfo: any;
  setAuthInfo;
  clearUserState;
  permissions;
}
export const UserInfoContext = createContext<Partial<UserInfo>>({});

export function useUserInfo() {
  const user = useContext(UserInfoContext);
  return user;
}

export default function UserInfoProvider({ children }) {
  const [usePersistedState, clearUserState] = createPersistedState(
    "epiphany",
    storage
  );
  const [authInfo, setAuthInfo] = usePersistedState("auth", null);
  const [userInfo, setUserInfo] = usePersistedState("userInfo", null);
  const permissions = () => {
    if(!authInfo || !(authInfo as any).tokenParse) return [];
    return (authInfo as any).tokenParse?.realm_access?.roles;
  }

  return (
    <UserInfoContext.Provider
      value={{
        authInfo,
        setAuthInfo,
        userInfo,
        setUserInfo,
        clearUserState,
        permissions,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
}
