import { createTheme, Shadows, Theme as MuiTheme } from "@mui/material/styles";
import colors from "./global-colors.json";
import themeBaseConfig from "./theme-base-config.json";
import { Theme } from "../../../models";
import { getShadowsFromToken } from "./utils";

// https://mui.com/material-ui/customization/palette/

type TImpactColor = {
  critical: React.CSSProperties["color"];
  high: React.CSSProperties["color"];
  medium: React.CSSProperties["color"];
  low: React.CSSProperties["color"];
};

type TBrandColor = {
  greyBlue: React.CSSProperties["color"];
  solidAlt: React.CSSProperties["color"];
};

type TBorderColor = {
  secondary: React.CSSProperties["color"];
};

declare module "@mui/material/styles" {
  interface Palette {
    impact: TImpactColor;
    brand: TBrandColor;
    border: TBorderColor;
  }

  interface PaletteOptions {
    impact?: TImpactColor;
    brand?: TBrandColor;
    border?: TBorderColor;
  }
}

const components = {
  // Name of the component
  MuiButtonBase: {
    defaultProps: {
      // The props to change the default for
      disableRipple: true, // No more ripple, on the whole application 💣!,
    },
    styleOverrides: {
      root: {
        "&.MuiButton-root": {
          textTransform: "none",
        },
        "&.MuiButton-root:focus": {
          outline: "none",
        },
      },
    },
  },
  // This effect reduce the page's performance
  // MuiDrawer: {
  //   styleOverrides: {
  //     root: {
  //       backdropFilter: "blur(4px)",
  //     },
  //   },
  // },
  // MuiDialog: {
  //   styleOverrides: {
  //     root: {
  //       backdropFilter: "blur(4px)",
  //     },
  //   },
  // },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }: { theme: MuiTheme }) => ({
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: theme.palette.action.selected,
        },
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }: { theme: MuiTheme }) => ({
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: theme.palette.action.selected,
        },
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        height: "fit-content",
        paddingTop: 2,
        border: "none",
      },
    },
  },
};

const generateTheme = (theme: Theme) => {
  const shadows = getShadowsFromToken(
    themeBaseConfig[theme].elevation
  ) as Shadows;
  return createTheme({
    palette: {
      mode: theme,
      primary: {
        main: themeBaseConfig[theme].primary.main.value,
        light: themeBaseConfig[theme].primary.light.value,
        dark: themeBaseConfig[theme].primary.dark.value,
        contrastText: themeBaseConfig[theme].primary.contrast.value,
      },
      secondary: {
        main: themeBaseConfig[theme].secondary.main.value,
        light: themeBaseConfig[theme].secondary.light.value,
        dark: themeBaseConfig[theme].secondary.dark.value,
        contrastText: themeBaseConfig[theme].secondary.contrast.value,
      },
      error: {
        main: themeBaseConfig[theme].error.main.value,
        light: themeBaseConfig[theme].error.light.value,
        dark: themeBaseConfig[theme].error.dark.value,
        contrastText: themeBaseConfig[theme].error.contrast.value,
      },
      warning: {
        main: themeBaseConfig[theme].warning.main.value,
        light: themeBaseConfig[theme].warning.light.value,
        dark: themeBaseConfig[theme].warning.dark.value,
        contrastText: themeBaseConfig[theme].warning.contrast.value,
      },
      info: {
        main: themeBaseConfig[theme].info.main.value,
        light: themeBaseConfig[theme].info.light.value,
        dark: themeBaseConfig[theme].info.dark.value,
        contrastText: themeBaseConfig[theme].info.contrast.value,
      },
      success: {
        main: themeBaseConfig[theme].success.main.value,
        light: themeBaseConfig[theme].success.light.value,
        dark: themeBaseConfig[theme].success.dark.value,
        contrastText: themeBaseConfig[theme].success.contrast.value,
      },
      text: {
        primary: themeBaseConfig[theme].text.primary.value,
        secondary: themeBaseConfig[theme].text.secondary.value,
        disabled: themeBaseConfig[theme].text.disabled.value,
      },
      divider: themeBaseConfig[theme].divider.value,
      background: {
        default: themeBaseConfig[theme].background.default.value, // Need to check
        paper: themeBaseConfig[theme].primary.main.value, // Need to check
      },
      action: {
        active: themeBaseConfig[theme].action.active.value,
        hover: themeBaseConfig[theme].action.hover.value,
        hoverOpacity: +themeBaseConfig[theme].action.hoverOpacity.value,
        selected: themeBaseConfig[theme].action.selected.value,
        selectedOpacity: +themeBaseConfig[theme].action.selectedOpacity.value,
        disabled: themeBaseConfig[theme].action.disabled.value,
        disabledBackground:
          themeBaseConfig[theme].action.disabledBackground.value,
        disabledOpacity: +themeBaseConfig[theme].action.disabledOpacity.value,
        focus: themeBaseConfig[theme].action.focus.value,
        focusOpacity: +themeBaseConfig[theme].action.focusOpacity.value,
      },
      impact: {
        critical: colors.red[500].value,
        high: colors.deepOrange[500].value,
        medium: colors.orange[500].value,
        low: colors.lightBlue[500].value,
      },
      brand: {
        greyBlue: "#717BBC",
        solidAlt: "#333741",
      },
      border: {
        secondary: "#1F242F",
      },
    },
    shape: {
      borderRadius: +themeBaseConfig[theme].shape.borderRadius.value,
    },
    shadows,
    typography: {
      // https://mui.com/material-ui/customization/typography/
      // fontFamily: ["Montserrat"].join(","),
    },
    components,
  });
};

const lightTheme = generateTheme("light");
const darkTheme = generateTheme("dark");

export { lightTheme, darkTheme, colors };
