import React, { forwardRef } from "react";
import { SxProps } from "@mui/system";
import { useTheme } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";

type TXOutlinedTextFieldProps = {
  sxProps?: SxProps;
} & Omit<TextFieldProps, "sx">;

const XOutlinedTextField = forwardRef(
  (
    props: TXOutlinedTextFieldProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { sxProps, ...rest } = props;
    const theme = useTheme();
    return (
      <TextField
        ref={ref}
        sx={{
          "& .MuiInputBase-root ": {
            fontSize: rest.size === "small" ? 12 : "auto",
            "& input::placeholder": {
              // color: "red",
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.primary.contrastText,
            },
          },
          // "& .MuiOutlinedInput-root": {
          //   fontSize: rest.size === "small" ? 12 : "auto",
          //   "& input::placeholder": {
          //     // color: "red",
          //   },
          //   "&.Mui-focused fieldset": {
          //     borderColor: theme.palette.primary.contrastText,
          //   },
          // },
          ...(sxProps && { ...sxProps }),
          "& .MuiInputLabel-root": {
            color: "primary.contrastText",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "primary.contrastText",
          },
          "& .MuiOutlinedInput-input.Mui-disabled": {
            WebkitTextFillColor: theme.palette.secondary.light,
          },
          "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.contrastText
          }
        }}
        {...rest}
        InputLabelProps={{
          sx: {
            fontSize: rest.size === "small" ? 12 : "auto",
            "&.MuiInputLabel-shrink": { top: 0 },
          },
        }}
      />
    );
  }
);

export default XOutlinedTextField;
