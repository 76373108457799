import React, { useCallback, useState, memo, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useLazyQuery } from "@apollo/client";
import * as colors from "@mui/material/colors";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useDebounce } from "use-debounce";
import { GET_SUGGESTIONS } from "../../app/queries";
import XInput from "../../components-v2/base-ui/XInput";
import { SearchIcon } from "../../components-v2/icons";
import SuggestItem from "./SuggestItem";
import DeviceListModal from "./DeviceListModal";

const GlobalSearchAutoComplete = ({ disabled }: { disabled?: boolean }) => {
  const theme = useTheme();
  const [value, setValue] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<string[]>([]);
  const [deviceModal, showDeviceModal] = useState<boolean>(false);
  const [debounceSearch] = useDebounce(inputValue, 1000);
  const [fetchSuggestions, { loading: loadingForSuggestions }] = useLazyQuery(
    GET_SUGGESTIONS,
    {
      variables: { word: debounceSearch },
      onCompleted: (data: any) => {
        try {
          const converted = data?.autocomplete.map((item) => item.value);
          setOptions(converted);
        } catch (e) {
          console.error("Fetching error in autocomplete", e);
          setOptions([]);
        }
      },
    }
  );

  const loading = loadingForSuggestions;

  const renderOption = useCallback((props, option, state) => {
    return (
      <SuggestItem
        {...props}
        key={option}
        inputValue={state.inputValue}
        item={option}
      />
    );
  }, []);

  const onInputChange = useCallback((_, newInputValue) => {
    setInputValue(newInputValue);
  }, []);

  const onChange = useCallback((_, newValue) => {
    setValue(newValue || "");
    if (newValue) {
      showDeviceModal(true);
    }
  }, []);

  useEffect(() => {
    if (!debounceSearch) return;

    fetchSuggestions({
      variables: {
        word: debounceSearch,
      },
    });
  }, [debounceSearch, fetchSuggestions]);

  return (
    <Box ml={2} sx={{ width: 420 }}>
      <Autocomplete
        disabled={disabled}
        loading={loading}
        loadingText={
          <Box component="small" color="primary.contrastText">
            Loading...
          </Box>
        }
        onChange={onChange}
        value={value}
        includeInputInList
        noOptionsText={
          <Box component="small" color="primary.contrastText">
            No options
          </Box>
        }
        onInputChange={onInputChange}
        options={loading ? [] : options}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <XInput
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            placeholder="Search for Anything (⌘ + K)"
            disabled={disabled}
            sxProps={{
              bgcolor: "transparent",
              padding: "4px 8px",
              "& .Mui-disabled": {
                WebkitTextFillColor: `${colors.grey[700]} !important`,
              },
            }}
            startAdornment={<SearchIcon color={theme.palette.action.hover} />}
            fullWidth
          />
        )}
        // isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={(option) => option || ""}
        renderOption={renderOption}
      />
      {deviceModal && value && (
        <DeviceListModal
          inheritSearch={value}
          open={deviceModal}
          onClose={() => showDeviceModal(false)}
        />
      )}
    </Box>
  );
};

export default memo(GlobalSearchAutoComplete);
