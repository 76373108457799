export const typeDefs = `
    type Query {
        buildings: [Building] @client
        widgets: [Widget] @client
        dashboard: Dashboard @client
    }

    type Building {
        _id: String
        name: String
    }

    type Widget {
        _id: String
        title: String
        type: String
        options: String
    }


`;

export const resolvers = {
    Query: {
        buildings: () => ([
            '1 Penn Plaza',
            '218 W 35th St',
            '20 W 34th St',
            '405 Lexington Ave',
            '89 E 42nd St',
            '11 Wall St',
        ].map((name) => ({
            __typename: "Building",
            name,
            _id: name.toLowerCase()
        }))),

        widgets: () => ([
            {
                __typename: "Widget",
                _id: 'wa1',
                title: 'Portfolio Spend 2019',
                type: 'alert',
                options: JSON.stringify({
                    number: "$23,400,020",
                    smallText: "+4%"
                })
            },
            {
                __typename: "Widget",
                _id: 'wa2',
                title: 'Assets Under Financing',
                type: 'alert',
                options: JSON.stringify({
                    number: "35"
                })
            },
            {
                __typename: "Widget",
                _id: 'wa3',
                title: 'Notes Due 2020',
                type: 'alert',
                options: JSON.stringify({
                    number: "14"
                })
            },
            {
                __typename: "Widget",
                _id: 'wt9',
                title: 'Projected Asset Value vs Equity',
                type: 'table',
                options: JSON.stringify({
                    data: data9
                })
            },
            {
                __typename: "Widget",
                _id: 'wt1',
                title: 'Cap Spend Plan',
                type: 'table',
                options: JSON.stringify({
                    data: data1
                })
            },
            {
                __typename: "Widget",
                _id: 'wt2',
                title: 'Portfolio Rollover',
                type: 'table',
                options: JSON.stringify({
                    data: data2
                })
            },
            {
                __typename: "Widget",
                _id: 'wt3',
                title: 'Portfolio Performance by AM',
                type: 'table',
                options: JSON.stringify({
                    data: data3
                })
            },
            {
                __typename: "Widget",
                _id: 'wt4',
                title: 'Lease Performance by AM',
                type: 'table',
                options: JSON.stringify({
                    data: data4
                })
            },
            {
                __typename: "Widget",
                _id: 'wt5',
                title: 'Top 5 Performing Properties Against Portfolio',
                type: 'table',
                options: JSON.stringify({
                    data: data5
                })
            },
            {
                __typename: "Widget",
                _id: 'wt6',
                title: 'Top 5 Performing Properties Against Market',
                type: 'table',
                options: JSON.stringify({
                    data: data6
                })
            },
            {
                __typename: "Widget",
                _id: 'wt7',
                title: 'Bottom 5 Performing Properties Against Market',
                type: 'table',
                options: JSON.stringify({
                    data: data7
                })
            },
            {
                __typename: "Widget",
                _id: 'wt8',
                title: 'Bottom 5 Performing Properties Against Portfolio',
                type: 'table',
                options: JSON.stringify({
                    data: data8
                })
            },
            {
                __typename: "Widget",
                _id: 'wts1',
                title: 'Portfolio Value',
                type: 'timeseries',
                options: JSON.stringify({
                    areaChartOptions: areaChartOptions,
                    areaChartData: areaChartData,
                })
            }
        ])
    }
};




const data1 = [
	['Name', '2019', '2020', ''],
	[
		`Roof	$21,648,185	$20,565,776	-5%`.split('\t'),
		`Connectivity 	$12,151,556	$13,366,712	+10%`.split('\t'),
		`Tech	$55,151,515	$77,212,121	+40%`.split('\t'),
		`Paving	$11,415,415	$12,556,957	+10%`.split('\t'),
		`Maintenance 	$59,986,332	$56,987,015	-5%`.split('\t')
	]
]

const data2 = [
	['Region', '2019', '2020', ''],
	[
		['Northwest', "1,678,946", "1,511,051", "-10%"],
		['Southwest', "751,616", "676,454", "-10%"],
		['Central', "645,615","581,054","-10%"],
		['Northeast', "2,518,567","2,896,352","+15%"],
		['Southeast', "589,931","678,421","+15%"],
	]
]

const data3 = [
	['Name', '2019', '2020', ''],
	[
		['John Smith', "578,181,851","699,600,040","+21%"],
		['Jane Smith', "262,115,156","314,538,187","+20%"],
		['Hugh Man', "361,518,185","379,594,094","+5%"],
		['May Bakerfield', "218,182,196","185,454,867","-15%"],
	]
]

const data4 = [
	['Name', '2019', '2020'],
	[
		['John Smith', "90%","96%"],
		['Jane Smith', "88%","94%"],
		['Hugh Man', "95%","93%"],
		['May Bakerfield', "75%","87%"],
	]
]

const data5 = [
	['Name', 'Location', 'Portfolio Name', 'Trend vs Portfolio'],
	[
		`1 Penn Plaza	New York	Northeast	+16%`.split('\t'),
		`218 W 35th St	New York	Northeast	+11%`.split('\t'),
		`11 Wall St	New York	Northeast	+9%`.split('\t'),
		`89 E 42nd St	New York	Northeast	+9%`.split('\t'),
		`203 Johnston Rd	Nashville	Central	+9%`.split('\t')
	]
]

const data6 = [
	['Name', 'Location', 'Portfolio Name', 'Trend vs Market'],
	[
		`1 Penn Plaza	New York	Northeast	+14%`.split('\t'),
		`218 W 35th St	New York	Northeast	+12%`.split('\t'),
		`11 Wall St	New York	Northeast	+9%`.split('\t'),
		`89 E 42nd St	New York	Northeast	+7%`.split('\t'),
		`203 Johnston Rd	Nashville	Central	+7%`.split('\t')
	]
]

const data7 = [
	['Name', 'Location', 'Portfolio Name', 'Trend vs Market'],
	[
		`12 State St	Ohio	Central	-20%`.split('\t'),
		`1 Main Plaza	Arizona	Southwest	-7%`.split('\t'),
		`22 Mayflower Rd	Florida	Southeast	-2%`.split('\t'),
		`10 Build Dr	New York	Northeast	-2%`.split('\t'),
		`20 Carry Sq	New Mexico	Southwest	-2%`.split('\t')
	]
]


const data8 = [
	['Name', 'Location', 'Portfolio Name', 'Trend vs Portfolio'],
	[
		`12 State St	Ohio	Central	-15%`.split('\t'),
		`1 Main Plaza	Arizona	Southwest	-15%`.split('\t'),
		`22 Mayflower Rd	Florida	Southeast	-9%`.split('\t'),
		`10 Build Dr	New York	Northeast	-7%`.split('\t'),
		`20 Carry Sq	New Mexico	Southwest	-3%`.split('\t')
	]
]


const data9 = [
	['Name', 'Location', 'Portfolio Name', 'Projection (2020)', ''],
	[
		`1 Penn Plaza	New York	Northeast	$118,505,963	+6%`.split('\t'),
		`218 W 35th St	New York	Northeast	$138,273,251	-2%`.split('\t'),
		`11 Wall St	New York	Northeast	$237,636,006	+7%`.split('\t'),
		`89 E 42nd St	New York	Northeast	$136,382,405	+12%`.split('\t')
	]
]

// const donutChartData = [
// 	{ 'label': 'Return Visitors', 'value': 784466, 'color': '#348fe2' },
// 	{ 'label': 'New Visitors', 'value': 416747, 'color': '#00ACAC' }
// ];


// const donutChartOptions = {
// 	donut: true,
// 	showLegend: false,
// 	growOnHover: false,
// 	arcsRadius: [
// 		{ inner: 0.65, outer: 0.93 },
// 		{ inner: 0.6, outer: 1 }
// 	],
// 	margin: { 'left': 10,'right':  10,'top': 10,'bottom': 10 },
// 	donutRatio: 0.5,
// 	// labelFormat: d3.format(',.0f')
// };

const areaChartOptions = {
	pointSize: 0.5,
	useInteractiveGuideline: true,
	durection: 300,
	showControls: false,
	controlLabels: {
		stacked: 'Stacked'
	},
	yAxis: {
		// tickFormat: d3.format(',.0f')
	},
	xAxis: {
		tickFormat: function(d) {
			return [
				'Q4 2019',
				'Q1 2020',
				'Q2 2020',
				'Q3 2020',
				'Q4 2020',
				'Q1 2020',
				'Q2 2020',
				'Q3 2020',
				'Q4 2020'
			][d]
		}
	}
};
const areaChartData = [

	{
		'key' : '1 Penn Plaza',
		'color' : '#4ed5fc',
		'values' : [
			{x: 0, y: 120003000}, // Q4 2019
			{x: 1, y: 108002700}, // Q1 2020
			{x: 2, y: 102602565}, // Q2 2020
			{x: 3, y: 112862822}, // Q3 2020
			{x: 4, y: 118505963}, // Q4 2020
		]
	},
	{
		'key' : '218 W 35th St',
		'color' : '#068c8d',
		'values' : [
			{x: 0, y: 140020001}, // Q4 2019
			{x: 1, y: 126018001}, // Q1 2020
			{x: 2, y: 119717101}, // Q2 2020
			{x: 3, y: 131688811}, // Q3 2020
			{x: 4, y: 138273251}, // Q4 2020
		]
	},
	{
		'key' : '20 W 34th St',
		'color' : '#63c2e6',
		'values' : [
			{x: 0, y: 180000002}, // Q4 2019
			{x: 1, y: 162000002}, // Q1 2020
			{x: 2, y: 194400002}, // Q2 2020
			{x: 3, y: 213840002}, // Q3 2020
			{x: 4, y: 224532002}, // Q4 2020
		]
	},

	{
		'key' : '405 Lexington Ave',
		'color' : '#0ccdd4',
		'values' : [
			{x: 0, y: 220200003}, // Q4 2019
			{x: 1, y: 180564002}, // Q1 2020
			{x: 2, y: 216676803}, // Q2 2020
			{x: 3, y: 242678019}, // Q3 2020
			{x: 4, y: 254811920}, // Q4 2020
		]
	},
	{
		'key' : '89 E 42nd St',
		'color' : '#2c7baf',
		'values' : [
			{x: 0, y: 120000004}, // Q4 2019
			{x: 1, y: 98400003}, // Q1 2020
			{x: 2, y: 118080004}, // Q2 2020
			{x: 3, y: 129888004}, // Q3 2020
			{x: 4, y: 136382405}, // Q4 2020
		]
	},

	{
		'key' : '11 Wall St',
		'color' : '#1f5ec0',
		'values' : [
			{x: 0, y: 200000005}, // Q4 2019
			{x: 1, y: 164000004}, // Q1 2020
			{x: 2, y: 196800005}, // Q2 2020
			{x: 3, y: 226320006}, // Q3 2020
			{x: 4, y: 237636006}, // Q4 2020
		]
	}
]
