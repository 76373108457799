import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  memo,
} from "react";
import { DocumentNode, useApolloClient, FetchPolicy } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import {
  GridApi,
  ColumnApi,
  ColDef,
  IDatasource,
  RowSelectedEvent,
  CellClickedEvent,
  IRowNode,
} from "ag-grid-community";
import { Box, Typography } from "@mui/material";
import { XCellRenderer } from "./XCellRenderer";
import { ColumnHeader } from "./ColumnHeader";
import { InfiniteHeaderPanel } from "./InfiniteHeaderPanel";
import { LoadingOverlay } from "./LoadingOverlay";
import { NoRowsOverlay } from "./NoRowsOverlay";
import "./override.scss";

const AGInfiniteScrollTable = ({
  label,
  fetchQuery,
  defaultColDef,
  columnDefs,
  rowSelection = "single",
  fetchCount = 50,
  variableName = "query",
  extraQueryParams,
  initialOrderBy,
  initialOrderDirection = "asc",
  initialSearchText,
  fetchPolicy = "network-only",
  isRowSelectable,
  disableColumnsSetting,
  onCellClicked,
  onRowClicked,
  onRowSelected,
  actionBarRenderer,
  customHeaderPanelRenderer,
  responseConvertFn,
}: {
  label?: string;
  customSearchText?: string;
  fetchQuery: DocumentNode;
  defaultColDef?: ColDef;
  columnDefs?: ColDef[];
  initialSearchText?: string;
  rowSelection?: "single" | "multiple";
  fetchCount?: number;
  extraQueryParams?: Record<string, string>;
  initialOrderBy?: string;
  initialOrderDirection?: "asc" | "desc" | null;
  fetchPolicy?: FetchPolicy;
  disableColumnsSetting?: boolean;
  isRowSelectable?: (params: IRowNode<any>) => boolean;
  onCellClicked?: (event: CellClickedEvent<any, any>) => void;
  onRowClicked?: (event: RowSelectedEvent<any>) => void;
  onRowSelected?: (event: RowSelectedEvent<any>) => void;
  actionBarRenderer?: (gridApi: GridApi) => React.ReactNode;
  customHeaderPanelRenderer?: (
    gridApi: GridApi,
    internalOption: {
      flexColDefs: ColDef<any>[];
      token?: string;
      totalRecords: number;
      loading?: boolean;
    },
    onSearch: (searchText: string) => void
  ) => React.ReactNode;
  responseConvertFn: (response: any) => {
    columns?: ColDef<any>[];
    rows: any[];
    totalResults: number; // !important: this should be number, if not, it will work wrongly
    token?: string;
    emptyDescription?: string;
  };
  variableName?: string;
}) => {
  const client = useApolloClient();
  const gridRef = useRef<any>();

  const containerStyle: any = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      position: "relative",
    }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [api, setApi] = useState<{
    grid: GridApi;
    column: ColumnApi;
  }>();

  const [internalOption, setInternalOption] = useState<{
    flexColDefs: ColDef<any>[];
    token?: string;
    totalRecords: number;
    loading?: boolean;
  }>({
    flexColDefs: [],
    totalRecords: 0,
    loading: false,
  });

  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<{
    order: "asc" | "desc" | null;
    orderBy: string | null | undefined;
  }>({
    order: initialOrderDirection,
    orderBy: initialOrderBy
      ? initialOrderBy
      : columnDefs?.length
      ? columnDefs[0].field
      : null,
  });

  const dataSource = useMemo<IDatasource>(
    () => ({
      getRows: (params) => {
        console.log("asking for " + params.startRow + " to " + params.endRow);
        const nextPage = params.endRow / fetchCount;
        setInternalOption((prev) => ({ ...prev, loading: true }));
        client
          .query({
            query: fetchQuery,
            variables: {
              [variableName]: [searchText || "*", fetchCount, nextPage],
              sort: sorting.orderBy,
              desc:
                sorting.order === "desc"
                  ? true
                  : sorting.order === "asc"
                  ? false
                  : null,
              ...(!!extraQueryParams && { ...extraQueryParams }),
            },
            fetchPolicy,
          })
          .then((response: any) => {
            const table = responseConvertFn(response.data);
            console.log("###table", table);
            setInternalOption({
              flexColDefs: !columnDefs?.length ? table.columns! : [],
              token: table.token,
              totalRecords: +table.totalResults,
              loading: false,
            });
            let lastRow = -1;
            if (table.totalResults <= params.endRow) {
              lastRow = table.totalResults;
            }
            // call the success callback
            params.successCallback(table.rows, lastRow);
          });
      },
    }),
    [
      client,
      sorting,
      searchText,
      fetchPolicy,
      extraQueryParams,
      fetchCount,
      fetchQuery,
      variableName,
      columnDefs,
      responseConvertFn,
    ]
  );

  const onGridReady = useCallback((params) => {
    setApi({ grid: params.api, column: params.columnApi });
  }, []);

  const onSort = useCallback(
    (columnId: string, order: "asc" | "desc" | null) => {
      setSorting({
        order,
        orderBy: columnId,
      });
    },
    []
  );

  const onSearch = useCallback((search: string) => {
    setSearchText(search);
  }, []);

  const components = useMemo(
    () => ({
      agColumnHeader: ColumnHeader,
    }),
    []
  );

  const refinedColumnDefs = useMemo(
    () => [
      ...(columnDefs?.length ? columnDefs : internalOption.flexColDefs).map(
        (colDef) => ({
          ...colDef,
          cellRenderer: XCellRenderer,
          // ...(!colDef.valueFormatter && { cellRenderer: XCellRenderer }),
        })
      ),
    ],
    [columnDefs, internalOption.flexColDefs]
  );

  const refinedDefaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      // resizable: true,
      // autoHeight: true,
      // wrapHeaderText: true,
      autoHeaderHeight: true,
      headerComponentParams: { sorting, onSort },
      ...(!!defaultColDef && { ...defaultColDef }),
    }),
    [defaultColDef, sorting, onSort]
  );

  useEffect(() => {
    setSearchText(initialSearchText || "*");
  }, [initialSearchText]);

  useEffect(() => {
    if (!api) return;
    const timeout = setTimeout(() => {
      api?.grid.setDatasource(dataSource);
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [api, dataSource]);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 1,
          borderLeft: "1px solid",
          borderRight: "1px solid",
          borderTop: "1px solid",
          borderColor: "rgba(255, 255, 255, 0.25)",
          bgcolor: "primary.main",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          display: "flex",
          alignItems: "center",
          gap: 2,
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        {api?.grid && api?.column && refinedColumnDefs.length > 0 ? (
          !!customHeaderPanelRenderer ? (
            customHeaderPanelRenderer(api.grid, internalOption, onSearch)
          ) : (
            <>
              {!!label && (
                <Typography variant="body1" fontWeight="bold">
                  {label}
                </Typography>
              )}
              <Typography
                variant="body1"
                fontSize={13}
                ml={1}
                whiteSpace="nowrap"
              >
                {internalOption.totalRecords.toLocaleString()} Records
              </Typography>
              {!!actionBarRenderer && actionBarRenderer(api.grid)}
              <Box mr="auto" />
              <InfiniteHeaderPanel
                gridColumnApi={api.column}
                disableColumnsSetting={disableColumnsSetting}
                initialSearchText={initialSearchText}
                onSearch={onSearch}
                token={internalOption.token}
              />
            </>
          )
        ) : (
          <></>
        )}
      </Box>
      <div style={containerStyle}>
        {internalOption.loading && <LoadingOverlay />}
        {!internalOption.loading && !internalOption.totalRecords && (
          <NoRowsOverlay />
        )}
        <div style={gridStyle} className="ag-theme-alpine-dark">
          <AgGridReact
            ref={gridRef}
            columnDefs={refinedColumnDefs}
            defaultColDef={refinedDefaultColDef}
            rowBuffer={0}
            components={components}
            rowSelection={rowSelection}
            suppressRowClickSelection={true}
            rowModelType={"infinite"}
            cacheBlockSize={fetchCount}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={fetchCount}
            maxBlocksInCache={10}
            onGridReady={onGridReady}
            onRowClicked={onRowClicked}
            onRowSelected={onRowSelected}
            onCellClicked={onCellClicked}
            isRowSelectable={isRowSelectable}
          />
        </div>
      </div>
    </Box>
  );
};

export default memo(AGInfiniteScrollTable);
