import React, { useCallback, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Box, Grid, CircularProgress, Avatar } from "@mui/material";
import * as colors from "@mui/material/colors";
import { toast } from "react-toastify";
import {
  CHANGE_MY_PASSWORD,
  // SET_RESET_USER_PASSWORD,
  // SET_USER_2FA,
  EDIT_USER_PROFILE,
} from "../../../../queries";
import UserConfirmationDialog, {
  UserConfirmationDialogProps,
  defaultPropsConfirmationDialog,
} from "../UserManagement/UserConfirmationDialog";
import useBoundStore from "../../../../../store";
import XModal from "../../../../../components-v2/base-ui/XModal";
import XButton from "../../../../../components-v2/base-ui/XButton";
import XPanel from "../../../../../components-v2/base-ui/XPanel";
import XOutlinedTextField from "../../../../../components-v2/base-ui/XOutlinedTextField";

const UserEditModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [currentUser, setCurrentUser] = useBoundStore((state) => [
    state.currentUser,
    state.setCurrentUser,
  ]);
  const [userInfo, setUserInfo] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmation: string;
  }>({
    email: currentUser?.email || "",
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    password: "",
    confirmation: "",
  });
  const [confirmationProps, setConfirmationProps] =
    useState<UserConfirmationDialogProps>(defaultPropsConfirmationDialog);

  const [updateUserQuery, { loading: loadingUpdateUser }] = useLazyQuery(
    EDIT_USER_PROFILE,
    {
      onCompleted: () => {
        toast.success("Updated the current user information successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCurrentUser({
          ...currentUser!,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        });
      },
      onError: () => {
        toast.error("Failed to update the current user information.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setUserInfo((prev) => ({ ...prev, ...currentUser }));
      },
    }
  );

  const [changeUserPassword, { loading: loadingChangePassword }] = useLazyQuery(
    CHANGE_MY_PASSWORD,
    {
      onCompleted: () => {
        toast.success("Updated the current user password successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
      onError: () => {
        toast.error("Failed to update the current user password.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      },
    }
  );

  const onFormValueChange = useCallback((e) => {
    setUserInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);
  const onUpdateUserInformation = useCallback(() => {
    setConfirmationProps({
      header: "Update User Information",
      message: "Are you sure you want to update your user information?",
      open: true,
      handleCancel: () => {
        setConfirmationProps({ ...defaultPropsConfirmationDialog });
      },
      handleAccept: () => {
        updateUserQuery({
          variables: {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          },
        });
        setConfirmationProps({ ...defaultPropsConfirmationDialog });
      },
    });
  }, [userInfo, updateUserQuery]);

  const onUpdatePassword = useCallback(() => {
    setConfirmationProps({
      header: "Password Reset",
      message: "Are you sure you want to update your password?",
      open: true,
      handleCancel: () => {
        setConfirmationProps({ ...defaultPropsConfirmationDialog });
      },
      handleAccept: () => {
        changeUserPassword({
          variables: {
            password: userInfo.password,
          },
        });
        setUserInfo((prev) => ({ ...prev, password: "", confirmation: "" }));
        setConfirmationProps({ ...defaultPropsConfirmationDialog });
      },
    });
  }, [userInfo, changeUserPassword]);

  const avatarName =
    (userInfo.firstName || "").charAt(0) + (userInfo.lastName || "").charAt(0);

  return (
    <XModal
      open={open}
      onClose={onClose}
      size="sm"
      header="Edit User Information"
      footer={<XButton onClick={onClose}>Close</XButton>}
    >
      <XPanel
        header="User Information"
        footer={
          <Box textAlign="right">
            <XButton
              disabled={!userInfo.firstName || !userInfo.lastName}
              size="small"
              sxProps={{
                "&.MuiButton-contained": {
                  bgcolor: "info.main",
                },
              }}
              onClick={onUpdateUserInformation}
            >
              Update information
            </XButton>
          </Box>
        }
      >
        {loadingUpdateUser ? (
          <Box textAlign="center" py={2}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Box mb={1}>Email *</Box>
              <XOutlinedTextField
                fullWidth
                name="email"
                size="small"
                variant="outlined"
                defaultValue={userInfo.email}
                disabled
                sxProps={{
                  mb: 2,
                  "& .Mui-disabled": {
                    WebkitTextFillColor: `${colors.grey[500]} !important`,
                  },
                }}
              />
              <Box mb={1}>First name *</Box>
              <XOutlinedTextField
                fullWidth
                name="firstName"
                size="small"
                variant="outlined"
                value={userInfo.firstName}
                required
                onChange={onFormValueChange}
                sxProps={{
                  mb: 2,
                }}
              />
              <Box mb={1}>Last name *</Box>
              <XOutlinedTextField
                fullWidth
                name="lastName"
                size="small"
                variant="outlined"
                value={userInfo.lastName}
                required
                onChange={onFormValueChange}
                sxProps={{
                  mb: 2,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  sx={{
                    width: 128,
                    height: 128,
                    fontWeight: "bold",
                    fontSize: 48,
                    boxShadow: 10,
                  }}
                >
                  {avatarName}
                </Avatar>
              </Box>
            </Grid>
          </Grid>
        )}
      </XPanel>
      <XPanel
        header="Password"
        sxProps={{ mt: 2 }}
        footer={
          <Box textAlign="right">
            <XButton
              disabled={
                !userInfo.password ||
                !userInfo.confirmation ||
                userInfo.password !== userInfo.confirmation
              }
              size="small"
              sxProps={{
                "&.MuiButton-contained": {
                  bgcolor: "info.main",
                },
              }}
              onClick={onUpdatePassword}
            >
              Update password
            </XButton>
          </Box>
        }
      >
        {loadingChangePassword ? (
          <Box textAlign="center" py={2}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <>
            <Box mb={1}>Password *</Box>
            <XOutlinedTextField
              fullWidth
              name="password"
              size="small"
              type="password"
              variant="outlined"
              value={userInfo.password}
              required
              onChange={onFormValueChange}
              sxProps={{
                mb: 2,
              }}
            />
            <Box mb={1}>Confirmation *</Box>
            <XOutlinedTextField
              fullWidth
              name="confirmation"
              size="small"
              variant="outlined"
              type="password"
              value={userInfo.confirmation}
              required
              onChange={onFormValueChange}
              sxProps={{
                mb: 2,
              }}
            />
          </>
        )}
      </XPanel>
      <UserConfirmationDialog {...confirmationProps} />
    </XModal>
  );
};

export default UserEditModal;
