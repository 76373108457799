import React, { ReactNode } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export type TXModalProps = {
  open: boolean;
  header?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  size?: DialogProps["maxWidth"];
  onClose: () => void;
};

export default function XModal(props: TXModalProps) {
  const { header, open, children, footer, onClose, size = "md" } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth={size}
      fullWidth
      sx={{
        "&.MuiDialog-root": {
          backdropFilter: "blur(4px)",
        },
        "& .MuiPaper-root": {
          backgroundImage: "none",
          bgcolor: "primary.dark",
        },
      }}
    >
      {!!header && <DialogTitle>{header}</DialogTitle>}
      <DialogContent dividers={!!header}>{children}</DialogContent>
      {!!footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
}
