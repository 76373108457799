import React from "react";
import { Box, Tooltip } from "@mui/material";

export const IconCell = ({
  value,
  rowData,
  iconFn,
  tooltipFn,
  onClick,
}: {
  value: boolean | number | string | Record<string, string>;
  rowData: Record<string, any>;
  onClick?: () => void;
  iconFn: (
    value: boolean | number | string | Record<string, string>,
    rowData: Record<string, any>
  ) => JSX.Element;
  tooltipFn?: (
    value: boolean | number | string | Record<string, string>,
    rowData: Record<string, any>
  ) => string;
}) => {
  const icon = iconFn(value, rowData);
  const tooltip = tooltipFn?.(value, rowData);
  return icon ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        color: !tooltip ? "grey" : "auto",
        pointerEvents: !tooltip ? "none" : "auto",
      }}
      onClick={() => {
        if (!!onClick) onClick();
      }}
    >
      {tooltip ? (
        <Tooltip title={tooltip} arrow>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon}
          </div>
        </Tooltip>
      ) : (
        icon
      )}
    </Box>
  ) : (
    <></>
  );
};
