export const convertResponseToArray = <T extends unknown>({
  response,
  dataField,
  subField,
  convertFn,
  hasPagination,
}: {
  response: any;
  dataField: string;
  subField?: string;
  convertFn?: (args: any) => T;
  hasPagination?: boolean;
}): {
  records: T[];
  totalPages?: number;
  totalResults?: number;
  hasError?: boolean;
  token?: string;
} => {
  let parsed: T[] = [];
  try {
    if (!subField) {
      parsed = JSON.parse(response[dataField][0].data).map((record: any) =>
        !!convertFn ? convertFn(record) : record
      );
      const totalPages: number = Number(
        response[dataField][0]?.totalPages || 0
      );
      const totalResults: number = Number(
        response[dataField][0]?.totalResults || 0
      );
      const token: string = response[dataField][0]?.token;
      return {
        records: parsed,
        ...(hasPagination && { totalPages, totalResults, token }),
      };
    } else {
      parsed = JSON.parse(response[dataField][subField]).map((record: any) =>
        !!convertFn ? convertFn(record) : record
      );
      return {
        records: parsed,
      };
    }
  } catch (e) {
    console.log(`Error in converting ${dataField}:`, e);
    return {
      records: [],
      hasError: true,
    };
  }
};

export const convertResponseToObj = <T extends unknown>({
  response,
  dataField,
  subField,
  convertFn,
}: {
  response: any;
  dataField: string;
  subField: string;
  convertFn?: (args: any) => T;
}): {
  data?: T;
  hasError?: boolean;
} => {
  try {
    const parsed = JSON.parse(response[dataField][subField]);
    return {
      data: parsed ? (!!convertFn ? convertFn(parsed) : parsed) : undefined,
    };
  } catch (e) {
    console.log(`Error in converting ${dataField}:`, e);
    return {
      hasError: true,
    };
  }
};

export const convertResponseToArr = <T extends unknown>({
  response,
  dataField,
  subField,
  convertFn,
}: {
  response: any;
  dataField: string;
  subField: string;
  convertFn?: (args: any) => T;
}): {
  data?: T;
  hasError?: boolean;
} => {
  try {
    const parsed = JSON.parse(response[dataField][subField]);
    return {
      data: parsed ? (!!convertFn ? convertFn(parsed) : parsed) : undefined,
    };
  } catch (e) {
    console.log(`Error in converting ${dataField}:`, e);
    return {
      hasError: true,
    };
  }
};

export const response2info = (response: any) => {
  try {
    const parsed = JSON.parse(response.value?.data?.widgetsGet?.data);
    return {
      title: parsed.title,
      tooltip: parsed.tooltip,
      data: parsed.data,
      total: parsed.total,
    };
  } catch (e) {
    console.error("converting error:", e);
    return {
      title: "",
      tooltip: "",
      data: null,
      total: 0,
    };
  }
};
