import React from "react";
import { Box } from "@mui/material";

export const BooleanCell = ({
  value,
  onClick,
}: {
  value: string;
  onClick?: () => void;
}) => {
  const booleanValue = !!value || (value || "").toLowerCase() === "true";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: booleanValue ? "success.main" : "error.main",
        height: '100%'
      }}
      onClick={() => (onClick ? onClick() : null)}
    >
      {booleanValue ? (
        <i className="fa-solid fa-check fa-2x"></i>
      ) : (
        <i className="fa-solid fa-xmark fa-2x"></i>
      )}
    </Box>
  );
};
