import { select } from "d3-selection";

export const tooltipGeneraterForMultiValues = ({
  title,
  content,
}: {
  title: string;
  content: Record<string, number>;
}) => {
  let innerHtml = `
  <div style="min-width: 80px; max-width: 240px; border: 1px solid #344054; padding: 4px; background: #101828; border-radius: 8px;">
  <div style="font-weight: bold; font-size: 12px; word-break: break-word; text-align: center;">${title}</div>`;
  for (const [key, value] of Object.entries(content)) {
    innerHtml += `<div style="font-weight: bold; font-size: 12px; word-break: break-word; text-align: center;">${key}: ${value}</div>`;
  }
  innerHtml += `</div>`;
  return innerHtml;
};

export const tooltipGenerater = <T>({ content }: { content: T }) => {
  let innerHtml = `
  <div style="min-width: 80px; max-width: 160px; border: 1px solid #344054; padding: 4px; background: #101828; border-radius: 8px;">
    <div style="font-weight: bold; font-size: 12px; word-break: break-word; text-align: center;">${content[0]}</div>
    <div style="font-weight: bold; font-size: 14px; text-align: center;">${content[1]}</div>
  </div>`;
  return innerHtml;
};

export const showTooltip = ({
  x,
  y,
  content,
}: {
  x: number;
  y: number;
  content: [string, unknown];
}) => {
  select(".app-tooltip")
    .style("display", "block")
    .style("pointer-events", "none")
    .style("left", x + 5 + "px")
    .style("top", y + 5 + "px")
    .html(tooltipGenerater<[string, unknown]>({ content }));
};

export const hideTooltip = () => {
  select(".app-tooltip").style("display", "none");
};

export const showTooltipForMultiValues = ({
  x,
  y,
  title,
  content,
}: {
  x: number;
  y: number;
  title: string;
  content: Record<string, number>;
}) => {
  select(".app-tooltip")
    .style("display", "block")
    .style("pointer-events", "none")
    .style("left", x + "px")
    .style("top", y + "px")
    .html(tooltipGeneraterForMultiValues({ title, content }));
};
