import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_QUICK_SEARCH } from "../../app/queries";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import XModal from "../../components-v2/base-ui/XModal";
import NotAvailableData from "../../components-v2/base-ui/NotAvailableData";
import XDonutChart from "../../components-v2/charts/XDonutChart";
import HostDetailContent from "../../components-v2/HostDetailContent";
import { riskLevelColor } from "../../app/globals/helpers";
import { CONDITION_COLOR } from "../../utils/color-util";
import XButton from "../../components-v2/base-ui/XButton";

interface IQuickItem {
  eipid: string;
  entryPoints?: Record<string, number>;
  hostname: string;
  id: string;
  ipAddress: string;
  os?: string;
  riskScore: string;
  totalRisks?: string;
}

const showLimit = 7;

const DeviceListModal = ({
  inheritSearch,
  open,
  onClose,
}: {
  inheritSearch: string;
  open: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  const [detailModal, showDetailModal] = useState(false);
  const [options, setOptions] = useState<IQuickItem[]>([]);

  const { loading } = useQuery(GET_QUICK_SEARCH, {
    variables: { word: inheritSearch },
    onCompleted: (data: any) => {
      try {
        const converted = (data?.quicksearch || []).map((item) => ({
          ...item,
          entryPoints: JSON.parse(item.entryPoints),
        }));
        setOptions(converted);
      } catch (e) {
        console.log("Converting error:", e);
      }
    },
  });

  const onMore = useCallback(() => {
    showDetailModal(true);
  }, []);
  const onItem = useCallback(
    (option: IQuickItem) => {
      history.push(`/nodedetail/${option.eipid}`);
      onClose();
    },
    [history, onClose]
  );

  return (
    <>
      <XModal
        open={open}
        onClose={onClose}
        size="sm"
        header={
          <Box fontSize={20}>Search results with "{inheritSearch}"</Box>
        }
      >
        {loading ? (
          <Box textAlign="center" p={4}>
            <CircularProgress color="info" />
          </Box>
        ) : !options.length ? (
          <NotAvailableData />
        ) : (
          <Box>
            <List sx={{ maxHeight: 640, overflowY: "auto" }}>
              {options.slice(0, showLimit).map((option) => (
                <ListItem
                  key={option.id}
                  disablePadding
                  sx={{ display: "block", bgcolor: "primary.main" }}
                >
                  <ListItemButton
                    sx={{
                      borderRadius: 1,
                      "&.MuiButtonBase-root": {
                        paddingTop: "2px",
                        paddingBottom: "2px",
                      },
                    }}
                    onClick={() => onItem(option)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        pr: 4,
                        pl: 2,
                        justifyContent: "center",
                        fontSize: 24,
                        color: "primary.contrastText",
                      }}
                    >
                      {option.os ? (
                        <i className={`ei ei-windows`}></i>
                      ) : (
                        <i className="far fa-question-circle"></i>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box>
                          <div>Hostname: {option.hostname}</div>
                          <div>IP Address: {option.ipAddress}</div>
                          <div>
                            Risk Score:{" "}
                            <strong
                              style={{
                                color: riskLevelColor(option.riskScore),
                              }}
                            >
                              {option.riskScore}
                            </strong>
                          </div>
                        </Box>
                      }
                      sx={{
                        "&>.MuiTypography-root": { fontSize: 12 },
                      }}
                    />
                    {!!option.entryPoints && (
                      <Box width={50}>
                        <XDonutChart
                          data={option.entryPoints}
                          predWidth={50}
                          predHeight={50}
                          colorDict={CONDITION_COLOR}
                          hideValues
                        />
                      </Box>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {options.length > showLimit && (
              <XButton onClick={onMore} fullWidth>
                More
              </XButton>
            )}
          </Box>
        )}
        {detailModal && (
          <XModal
            header="Device List"
            open={detailModal}
            onClose={() => showDetailModal(false)}
            size="lg"
          >
            <HostDetailContent inheritedSearchText={inheritSearch} />
          </XModal>
        )}
      </XModal>
    </>
  );
};

export default DeviceListModal;
