import Keycloak from "keycloak-js";

export function buildKeycloak(userInfo, environment) {
  let keycloak;
  const {
    EIP_KEYCLOAK_ENABLED,
    EIP_KEYCLOAK_DOMAIN_REALM,
    EIP_KEYCLOAK_URL,
    EIP_KEYCLOAK_CLIENT_ID,
    EIP_KEYCLOAK_DEFAULT_REALM,
  } = environment;

  if (EIP_KEYCLOAK_ENABLED && EIP_KEYCLOAK_DOMAIN_REALM) {
    keycloak = new Keycloak({
      realm: userInfo.realm,
      url: EIP_KEYCLOAK_URL,
      clientId: EIP_KEYCLOAK_CLIENT_ID,
    });
  } else {
    keycloak = getKeycloakInstance(
      EIP_KEYCLOAK_ENABLED,
      EIP_KEYCLOAK_DEFAULT_REALM,
      EIP_KEYCLOAK_URL,
      EIP_KEYCLOAK_CLIENT_ID
    );
  }
  return keycloak;
}

function getKeycloakInstance(
  isKeycloakEnabled,
  realm,
  keycloakUrl,
  keycloakClientId
) {
  if (isKeycloakEnabled) {
    return new Keycloak({
      realm,
      url: keycloakUrl,
      clientId: keycloakClientId,
    });
  }
  return new Keycloak();
}
