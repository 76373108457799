import React, { forwardRef } from "react";
import { SxProps } from "@mui/material/styles";
import InputBase, { InputBaseComponentProps } from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";

type IXInputProps = {
  sxProps?: SxProps;
  clearable?: boolean;
  onClear?: () => void;
} & Omit<InputBaseComponentProps, "sx">;

const XInput = forwardRef((props: IXInputProps, ref) => {
  const { sxProps, clearable, onClear, ...rest } = props;
  return (
    <InputBase
      ref={ref}
      sx={{
        backgroundColor: "primary.dark",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "primary.light",
        padding: "4px 8px",
        fontSize: 14,
        "& .MuiInputBase-input": {
          padding: "4px",
        },
        ...(sxProps && { ...sxProps }),
      }}
      endAdornment={
        (clearable && rest.value) ? (
          <CloseIcon
            onClick={onClear}
            sx={{ fontSize: 16, cursor: "pointer" }}
          />
        ) : (
          <></>
        )
      }
      {...rest}
    />
  );
});

export default XInput;
