import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "./Drawer";
import DrawerHeader from "./DrawerHeader";
import MenuNavList from "./MenuNavList";
// import MenuNavSetting from "./MenuNavSetting";
import TopLogo from "./TopLogo";
import BottomLogo from "./BottomLogo";
import useBoundStore from "../../store";
import { useMe } from "../../app/pages/auth/LoginAuth";
import {
  DashboardIcon,
  AttackPathToolsIcon,
  AssetToolsIcon,
  IdentityToolsIcon,
  UserManagementIcon,
  TicketManagementIcon,
  SourceManagementIcon,
  AgentConfigurationIcon,
  ScoutReportIcon,
  // SupportIcon,
  // SettingsIcon,
  SearchIcon,
  DocumentsIcon,
} from "../../components-v2/icons";
import { TListItem } from "../../components-v2/MenuList";
import XInput from "../../components-v2/base-ui/XInput";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import layoutConfig from "../layout-config.json";

// const navSettings = [
//   {
//     label: "Support",
//     value: "support",
//     icon: <SupportIcon />,
//   },
//   {
//     label: "Settings",
//     value: "settings",
//     icon: <SettingsIcon />,
//   },
// ];

// This needs API changes.
const menuIcons = {
  Dashboards: <DashboardIcon />,
  "Attack Path Tools": <AttackPathToolsIcon />,
  "Asset Tools": <AssetToolsIcon />,
  "Identity Tools": <IdentityToolsIcon />,
  "Tickets Management": <TicketManagementIcon />,
  Documents: <DocumentsIcon />,
  "User Management": <UserManagementIcon />,
  "Source Management": <SourceManagementIcon />,
  "Synapse Engine": <AgentConfigurationIcon />,
  "Scout Engine": (
    <i className="fa fa-border-none fa-2x" style={{ color: "white" }} />
  ),
  "Scout Reports": <ScoutReportIcon />,
};

const convertMenuItems = (items: any) => {
  if (!items.length) return [];
  return items.map((item) => ({
    label: item.title || "Untitled",
    value: item.page || item.title,
    ...(menuIcons[item.title] && { icon: menuIcons[item.title] }),
    ...(item.badge && { badge: item.badge }),
    ...(item.submenu?.length && {
      children:
        item.title === "Scout Reports"
          ? convertMenuItems(item.submenu).map((sb) => ({
              ...sb,
              value: `scout-report/${sb.value}`,
            }))
          : convertMenuItems(item.submenu),
    }),
  }));
};

const findMatchedMenuItem = (items: any, pathname: string) => {
  for (const item of items) {
    if (pathname.includes(`/${item.value}`)) return item;
    if (item.children?.length) {
      const result = findMatchedMenuItem(item.children, pathname);
      if (result) return result;
    }
  }
  return null;
};

function findMenusWithText(data, searchText) {
  const results: any = [];

  for (const menu of data) {
    const clonedMenu = { ...menu };
    if (clonedMenu.label.toLowerCase().includes(searchText.toLowerCase())) {
      if (clonedMenu.children) {
        clonedMenu.children = findMenusWithText(
          clonedMenu.children,
          searchText
        );
        if (!clonedMenu.children.length) {
          clonedMenu.children = menu.children;
        }
      }

      results.push(clonedMenu);
    } else {
      const matchedChildren = findMenusWithText(
        clonedMenu.children || [],
        searchText
      );
      if (
        matchedChildren.length > 0 ||
        clonedMenu.label.includes(searchText.toLowerCase())
      ) {
        const clonedParent = { ...clonedMenu };
        clonedParent.children = matchedChildren;
        results.push(clonedParent);
      }
    }
  }

  return results;
}

const Sider = () => {
  const { updateConfig, config } = useBoundStore((state) => state);
  const me: any = useMe();
  const history = useHistory();
  const location = useLocation();

  const [menuText, setMenuText] = useState<string>("");

  const dynamicMenus = useMemo(() => {
    if (!me.menuConfig) return [];
    const asideMenus = convertMenuItems(me.menuConfig.aside?.items);
    const adminMenus = convertMenuItems(me.menuConfig.admin?.items);
    const menus = [...asideMenus, ...adminMenus];
    if (!menuText) return menus;
    const filteredMenu = findMenusWithText(menus, menuText);
    return filteredMenu;
  }, [me.menuConfig, menuText]);

  const currentMenuItem = useMemo(() => {
    const item = findMatchedMenuItem(dynamicMenus, location.pathname);
    if (!item && !!history.location.state?.prevPath) {
      return findMatchedMenuItem(dynamicMenus, history.location.state.prevPath);
    }
    return item;
  }, [location.pathname, history.location.state, dynamicMenus]);

  const onDrawerExpand = useCallback(() => {
    updateConfig();
  }, [updateConfig]);

  const onNavItemClick = useCallback(
    (navItem: TListItem) => {
      history.push(`/${navItem.value}`);
    },
    [history]
  );
  return (
    <Drawer variant="permanent" open={config.siderExpanded}>
      <DrawerHeader expanded={config.siderExpanded}>
        <TopLogo expanded={config.siderExpanded} />
      </DrawerHeader>
      <Box
        sx={{
          height: `calc(100% - ${
            config.siderExpanded
              ? layoutConfig.extendedLogoHeight
              : layoutConfig.topNavbarHeight
          }px)`,
          display: "flex",
          flexDirection: "column",
          bgcolor: "primary.main",
        }}
      >
        {config.siderExpanded && (
          <XInput
            placeholder="Search"
            sxProps={{ m: 2, bgcolor: "primary.light", border: "none" }}
            startAdornment={<SearchIcon />}
            value={menuText}
            onChange={(e) => setMenuText(e.target.value)}
            clearable
            onClear={() => setMenuText("")}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <MenuNavList
            siderExpanded={config.siderExpanded}
            list={dynamicMenus}
            selectedItem={currentMenuItem || dynamicMenus[0]}
            onSelectedItem={onNavItemClick}
          />
        </Box>
        {/* <MenuNavSetting
          siderExpanded={config.siderExpanded}
          list={navSettings}
          selectedItem={{ label: "Foo", value: "Foo" }}
          onSelectedItem={() => {}}
        /> */}
        <BottomLogo expanded={config.siderExpanded} />
      </Box>
      <Box
        sx={{
          position: "fixed",
          top: "53%",
          transform: "translate(35%, -50%)",
          left: config.siderExpanded
            ? layoutConfig.drawerWidth
            : layoutConfig.condensedDrawerWidth,
          width: 14,
          height: 148,
          borderRadius: 4,
          bgcolor: "grey.600",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          ":hover": {
            bgcolor: "grey.500",
          },
        }}
        onClick={onDrawerExpand}
      >
        {config.siderExpanded ? (
          <KeyboardArrowLeft sx={{ color: "primary.light", fontSize: 28 }} />
        ) : (
          <KeyboardArrowRight sx={{ color: "primary.light", fontSize: 28 }} />
        )}
      </Box>
    </Drawer>
  );
};

export default Sider;
