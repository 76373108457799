import { gql } from "@apollo/client";

export const GET_WIDGETS = gql`
  {
    getWidgets {
      name
      data
    }
  }
`;

export const GET_ATTACK_TYPES = gql`
  query {
    listAttackTypes {
      description
      searchKey
      __typename
    }
  }
`;

export const GET_NODE = gql`
  query filterNode($card_id: String!, $info_type: String!, $context: String) {
    getNode(cardId: $card_id, infoType: $info_type, context: $context) {
      name
      data
      updated
      status
    }
  }
`;

export const GET_ASYNC_NODE = gql`
  query filterNode($node_id: String!, $info_type: String!, $context: String) {
    getAsyncNode(nodeId: $node_id, infoType: $info_type, context: $context) {
      name
      token
      status
      data
      __typename
    }
  }
`;

export const GET_HOSTS = gql`
  query filterHosts(
    $host: String!
    $pag: Int
    $perPage: Int
    $sort: String
    $desc: Boolean
  ) {
    getHostsPag(
      query: $host
      pag: $pag
      perPage: $perPage
      sort: $sort
      desc: $desc
    ) {
      totalPages
      totalHits
      hits
      token
    }
  }
`;
export const GET_SUGGESTIONS = gql`
  query autocompleter($word: String!) {
    autocomplete(query: $word) {
      value
    }
  }
`;
//{"operationName": "quicksearching","variables": {"word": "RUBEN"},"query": "query quicksearching($word: String!) {quicksearch(query: $word) {id eipid hostname ipAddress os totalRisks entryPoints riskScore}}"}
export const GET_QUICK_SEARCH = gql`
  query quicksearching($word: String!) {
    quicksearch(query: $word) {
      id
      eipid
      hostname
      ipAddress
      os
      totalRisks
      entryPoints
      riskScore
    }
  }
`;
//{"operationName": "quicksearchingpage","variables": {"vars": ["RUBEN", "10", "1"]},"query": "query quicksearchingpage($vars: [String]!) {quicksearchpage(query: $vars) {data next previous page totalPages totalResults}}"}
export const GET_PAGE_SEARCH = gql`
  query quicksearchingpage($vars: [String]!, $sort: String, $desc: Boolean) {
    quicksearchpage(query: $vars, sort: $sort, desc: $desc) {
      data
      next
      previous
      sortField
      sortDirection
      page
      totalPages
      totalResults
      token
    }
  }
`;

export const GET_ROGUE_FILTER_INFO = gql`
  query rogueFilterInfo(
    $page: Int!
    $rogue: String!
    $perPage: Int!
    $sort: String
    $desc: Boolean
  ) {
    rogueFilter(
      page: $page
      rogue: $rogue
      perPage: $perPage
      sort: $sort
      desc: $desc
    ) {
      totalPages
      totalHits
      hits
      meta
      token
    }
  }
`;

//

export const CREATE_USER = gql`
  query createUser(
    $isAdmin: Boolean!
    $resetEmail: Boolean!
    $email: String!
    $firstName: String!
    $lastName: String!
    $roles: [String]!
    $groups: [String]!
    $rights: [String]!
  ) {
    createUser(
      isAdmin: $isAdmin
      resetEmail: $resetEmail
      email: $email
      firstName: $firstName
      lastName: $lastName
      groups: $groups
      roles: $roles
      rights: $rights
    ) {
      result
    }
  }
`;

export const UPDATE_USER = gql`
  query updateUser(
    $userId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $roles: [String]!
    $groups: [String]!
    $rights: [String]!
  ) {
    updateUser(
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      groups: $groups
      roles: $roles
      rights: $rights
    ) {
      result
    }
  }
`;

export const SET_USER_AS_ADMIN = gql`
  query setUserAsAdmin($userId: String!, $setAsAdmin: Boolean!) {
    setUserAsAdmin(userId: $userId, setAsAdmin: $setAsAdmin) {
      result
      __typename
    }
  }
`;

export const EDIT_USER_PROFILE = gql`
  query editProfile($firstName: String!, $lastName: String!) {
    editProfile(firstName: $firstName, lastName: $lastName) {
      result
      __typename
    }
  }
`;

export const CHANGE_MY_PASSWORD = gql`
  query changeMyPassword($password: String!) {
    changeMyPassword(password: $password) {
      result
      __typename
    }
  }
`;

export const GENERATE_ONE_TIME_PASSWORD = gql`
  query generateNewTmpPasswordForUser($userId: String!) {
    generateNewTmpPasswordForUser(userId: $userId) {
      result
      __typename
    }
  }
`;

export const DELETE_USER = gql`
  query deleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      result
    }
  }
`;

export const SET_RESET_USER_PASSWORD = gql`
  query sendResetPassword($userId: String!) {
    sendResetPassword(userId: $userId) {
      result
    }
  }
`;

export const SET_USER_2FA = gql`
  query set2faAction($userId: String!, $enabled: Boolean!) {
    set2faAction(userId: $userId, enabled: $enabled) {
      result
    }
  }
`;

export const GET_SYSTEMS_RIGHTS = gql`
  query listSystemRights($query: String!) {
    listSystemRights(query: $query) {
      rights
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getAllUsers($vars: String!) {
    getAllUsers(query: $vars) {
      id
      firstName
      lastName
      roles
      groups
      rights
      email
      isAdmin
      isEnabled
      otpStatus
      created
    }
  }
`;

export const GET_ALL_USERS_BASIC = gql`
  query listAllUsersBasicInfo($vars: String!) {
    listAllUsersBasicInfo(query: $vars) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query getAllRoles($vars: String!) {
    getAllRoles(query: $vars) {
      roles
    }
  }
`;

export const CREATE_ROLE = gql`
  query createRole($roleName: String!, $rights: [String]!, $desc: String!) {
    createRole(roleName: $roleName, rights: $rights, desc: $desc) {
      roles
    }
  }
`;

export const UPDATE_ROLE = gql`
  query updateRole(
    $roleId: String!
    $roleName: String!
    $rights: [String]!
    $desc: String!
  ) {
    updateRole(
      roleId: $roleId
      roleName: $roleName
      rights: $rights
      desc: $desc
    ) {
      roles
    }
  }
`;

export const DELETE_ROLE = gql`
  query deleteRole($roleName: String!) {
    deleteRole(roleName: $roleName) {
      roles
    }
  }
`;

export const GET_ALL_GROUPS = gql`
  query getAllGroups($vars: String!) {
    getAllGroups(query: $vars) {
      groups
    }
  }
`;

export const CREATE_GROUP = gql`
  query createGroup($groupName: String!, $rights: [String]!, $desc: String!) {
    createGroup(groupName: $groupName, rights: $rights, desc: $desc) {
      groups
    }
  }
`;

export const DELETE_GROUP = gql`
  query deleteGroup($groupId: String!) {
    deleteGroup(groupId: $groupId) {
      groups
    }
  }
`;

export const UPDATE_GROUP = gql`
  query updateGroup(
    $groupId: String!
    $groupName: String!
    $rights: [String]!
    $desc: String!
  ) {
    updateGroup(
      groupId: $groupId
      groupName: $groupName
      rights: $rights
      desc: $desc
    ) {
      groups
    }
  }
`;

export const ADD_USER_ROLE = gql`
  query addRoleToUser($roleId: String!, $userId: String!, $roleName: String!) {
    addRoleToUser(roleId: $roleId, userId: $userId, roleName: $roleName) {
      roles
    }
  }
`;

export const ADD_USER_GROUP = gql`
  query addGroupToUser($groupId: String!, $userId: String!) {
    addGroupToUser(groupId: $groupId, userId: $userId) {
      groups
    }
  }
`;

export const DELETE_USER_ROLE = gql`
  query deleteUserRole($roleId: String!, $userId: String!, $roleName: String!) {
    deleteUserRole(roleId: $roleId, userId: $userId, roleName: $roleName) {
      roles
    }
  }
`;

export const DELETE_USER_GROUP = gql`
  query deleteUserGroup($groupId: String!, $userId: String!) {
    deleteUserGroup(groupId: $groupId, userId: $userId) {
      groups
    }
  }
`;

export const USER_BULK_OPERATION = gql`
  query ($operation: String!, $users: [String]!, $values: [String]!) {
    userBulkOperation(operation: $operation, users: $users, values: $values) {
      results
      __typename
    }
  }
`;

export const ADD_PERMISSIONS_USERS = gql`
  query addPermissionsToUser($userId: String!, $permissions: String!) {
    addPermissionToUser(userId: $userId, permissions: $permissions) {
      permissions
    }
  }
`;

export const SAVE_DYNAMIC_DASHBOARD_PAGE = gql`
  query Query(
    $saveDynamicDashboardPagePagename: String!
    $saveDynamicDashboardPageContent: String!
    $saveDynamicDashboardPagePageid: String
    $saveDynamicDashboardPageIsDefault: Boolean
  ) {
    saveDynamicDashboardPage(
      pagename: $saveDynamicDashboardPagePagename
      content: $saveDynamicDashboardPageContent
      pageid: $saveDynamicDashboardPagePageid
      isDefault: $saveDynamicDashboardPageIsDefault
    ) {
      id
      content
      pagename
      isDefault
    }
  }
`;

export const GET_DYNAMIC_DASHBOARD_PAGE = gql`
  query Query($getDynamicDashboardPagePageid: String!) {
    getDynamicDashboardPage(pageid: $getDynamicDashboardPagePageid) {
      pagename
      content
      id
      isDefault
    }
  }
`;

export const DELETE_DYNAMIC_DASHBOARD_PAGE = gql`
  query Query($deleteDynamicDashboardPagePageid: String!) {
    deleteDynamicDashboardPage(pageid: $deleteDynamicDashboardPagePageid) {
      result
    }
  }
`;

export const SAVE_USER_SEARCH = gql`
  query saveUserSearch($page: String!, $value: String!) {
    saveUserSearch(page: $page, value: $value) {
      page
      value
    }
  }
`;

export const GET_USER_SEARCH = gql`
  query getUserSearchesInfo($search: String!) {
    getUserSearches(query: $search) {
      page
      value
    }
  }
`;

export const GET_BIM_LIST = gql`
  query listingBim($query: [String]!, $sort: String, $desc: Boolean) {
    listBim(query: $query, sort: $sort, desc: $desc) {
      data
      next
      previous
      page
      totalPages
      totalResults
      token
    }
  }
`;

export const SAVE_BIM_DATA = gql`
  query savingBim($data: JSONString!, $infoType: String!) {
    saveBim(data: $data, infoType: $infoType) {
      name
      data
      updated
      status
    }
  }
`;

export const LOAD_BIM_DATA = gql`
  query loadingBim($infoType: String!, $lastUpdated: String!) {
    loadBim(infoType: $infoType, lastUpdated: $lastUpdated) {
      name
      data
      updated
      status
    }
  }
`;

export const GET_PATH_FINDER_IDENTIFIED = gql`
  query getPathfinder($query: [String]!) {
    getPathfinder(query: $query) {
      data
      next
      previous
      page
      totalPages
      totalResults
    }
  }
`;

export const GET_VULNCHECK = gql`
  query getVulncheck($query: [String]!, $sort: String, $desc: Boolean) {
    getVulncheck(query: $query, sort: $sort, desc: $desc) {
      data
      header
      next
      previous
      page
      totalPages
      totalResults
      token
    }
  }
`;

export const GET_FOOTHOLD = gql`
  query getFoothold($query: [String]!, $sort: String, $desc: Boolean) {
    getFoothold(query: $query, sort: $sort, desc: $desc) {
      data
      header
      next
      previous
      page
      totalPages
      totalResults
      token
      __typename
    }
  }
`;

export const GET_THREAT_ACTOR = gql`
  query getThreatActor($query: [String]!, $sort: String, $desc: Boolean) {
    getThreatActor(query: $query, sort: $sort, desc: $desc) {
      data
      header
      next
      previous
      page
      totalPages
      totalResults
      token
    }
  }
`;

//GET DYNAMIC WIDGETS

export const GET_WIDGETS_LIST = gql`
  query Query {
    widgetsList {
      name
      widgetType
      icon
    }
  }
`;

//GET DYNAMIC WIDGET CONTENT
export const GET_WIDGET_CONTENT = gql`
  query Query($widgetsGetWidgetType: String!) {
    widgetsGet(widgetType: $widgetsGetWidgetType) {
      data
      token
    }
  }
`;

export const GET_ACTIVE_DIRECTORY_DOMAINS = gql`
  {
    getActiveDirectoryDomains {
      data
      totalPages
      __typename
    }
  }
`;

export const GET_ACTIVE_DIRECTORY_WINDOW = gql`
  query Query(
    $domain: String!
    $infoType: String!
    $query: [String]!
    $sort: String
    $desc: Boolean
  ) {
    getActiveDirectoryWindow(
      domain: $domain
      infoType: $infoType
      query: $query
      sort: $sort
      desc: $desc
    ) {
      header
      data
      emptyDescription
      next
      previous
      page
      totalPages
      totalResults
      __typename
    }
  }
`;

export const GET_BOX_AUTH_TOKEN = gql`
  query {
    getBoxAuthToken {
      token
      folder
      error
    }
  }
`;

export const GET_AGENTS = gql`
  query getAgents($query: [String]!, $sort: String!, $desc: Boolean) {
    getAgents(query: $query, sort: $sort, desc: $desc) {
      data
      next
      previous
      page
      totalPages
      totalResults
      __typename
    }
  }
`;
