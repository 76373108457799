import React from "react";
import { Route, Switch } from "react-router-dom";
import Logout from "./Logout";
import WithAuth from "./WithAuth";
import LoginAuth from "./LoginAuth";

const LogoutWithAuth = WithAuth(Logout);
const LogintWithAuth = WithAuth(LoginAuth);

export default function Auth({ children }) {
  return (
    <Switch>
      <Route path="/auth/logout">
        <LogoutWithAuth></LogoutWithAuth>
      </Route>
      <Route path="/">
        <LogintWithAuth>{children}</LogintWithAuth>
      </Route>
    </Switch>
  );
}
