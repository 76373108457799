import React, { lazy, useEffect, useState, Suspense } from "react";
import {
  withRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import useBoundStore from "./store";
import Layout from "./layouts/Layout";
import { useMe } from "./app/pages/auth/LoginAuth";
import { useUserInfo } from "./app/pages/auth/entryPage/UserInfoContext";
import routes from "./config/page-route";

const GuardedRoute = lazy(() => import("./app/pages/home/GuardedRoute"));

const App = ({ apolloClient }) => {
  const me: any = useMe();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const action = searchParams.get("action_hint");
  const { authInfo } = useUserInfo();
  const [defaultPage, setDefaultPage] = useState("loading");
  // Prefetch all ticket configuration data.
  const {
    fetchUserDict,
    fetchTicketStatuses,
    fetchTicketPriorities,
    fetchPlatformAreas,
    fetchTicketTypes,
    fetchTicketResolutions,
    setupNofication,
    loadingUsers,
    loadingTicketPriorities,
    loadingTicketTypes,
    loadingPlatformAreas,
    loadingTicketResolutions,
    loadingTicketStatus,
  } = useBoundStore((state) => state);

  useEffect(() => {
    if (action === "logout") {
      me.logout();
    }
  }, [action, me]);

  useEffect(() => {
    document.title = "Epiphany Intelligence Platform";
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (authInfo?.token) {
      setupNofication(apolloClient, authInfo.token);
    }
  }, [apolloClient, authInfo?.token, setupNofication]);

  useEffect(() => {
    if (me.menuConfig) {
      setDefaultPage(me.menuConfig.defaultPage);
    }
  }, [me.menuConfig]);

  useEffect(() => {
    fetchUserDict(apolloClient);
    fetchTicketStatuses(apolloClient);
    fetchTicketPriorities(apolloClient);
    fetchPlatformAreas(apolloClient);
    fetchTicketTypes(apolloClient);
    fetchTicketResolutions(apolloClient);
  }, [
    apolloClient,
    fetchUserDict,
    fetchTicketStatuses,
    fetchTicketPriorities,
    fetchPlatformAreas,
    fetchTicketTypes,
    fetchTicketResolutions,
  ]);

  if (
    defaultPage === "loading" ||
    loadingUsers ||
    loadingTicketStatus ||
    loadingTicketPriorities ||
    loadingTicketResolutions ||
    loadingPlatformAreas ||
    loadingTicketTypes
  ) {
    return <LinearProgress />;
  }

  return (
    <Layout>
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          <Redirect exact from="/" to={`/${defaultPage}`} />
          {routes.map(({ path, exact, isNotGuarded, component }, k) =>
            isNotGuarded ? (
              <Route key={k} exact={exact} path={path} component={component} />
            ) : (
              <GuardedRoute
                key={k}
                exact={exact}
                path={path}
                component={component}
              />
            )
          )}
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default withRouter(App);
