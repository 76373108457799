import React, { useContext, useEffect, useState, useCallback } from "react";
import { Box, TextField, useTheme, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as colors from "@mui/material/colors";
import { getDomainFromEmail } from "./UserInfo";
import { buildKeycloak } from "../../../../keycloak";
import useImperativeQuery from "./ImperativeQuery";
import { ApolloQueryResult, gql } from "@apollo/client";
import { EnvironmentContext } from "../../../utils/EnvironmentProvider";
import { useUserInfo } from "./UserInfoContext";
import XButton from "../../../../components-v2/base-ui/XButton";
import {
  RevealdLogoSvg,
  EpiphanyLoginLogo,
  RevealdLogoBigSvg,
  GoCyberLogoSvg,
  GoCyberProductLogoSvg,
} from "../../../../components-v2/icons";
import warriorImg from "../../../../assets/warrior.png";

const LOGIN_EMAIL_LOOKUP = gql`
  query filterRealm($identifier: String!) {
    getRealmMapping(query: $identifier) {
      result
    }
  }
`;

async function loginLookup$(callQuery: Promise<ApolloQueryResult<any>>) {
  try {
    const { data } = await callQuery;
    const realm = data?.getRealmMapping?.result;
    if (realm) {
      return { realm };
    }
  } catch (error) {
    console.error("Error EIP:", error);
    throw new Error(`Error:  ${error}`);
  }

  throw new Error(`Error:  Not valid realm`);
}

function validateEmail(email) {
  if (!email) return true;
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  fontFamily: "Proxima Nova",
  [theme.breakpoints.down("md")]: {
    display: "auto",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const LetfPanel = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.primary.dark,
  padding: theme.spacing(14),
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("md")]: {
    width: 560,
  },
}));

const RightPanel = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `url(${warriorImg})`,
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
  padding: 32,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    minHeight: 960,
    alignItems: "center",
  },
  [theme.breakpoints.up("md")]: {
    width: "calc(100% - 560px)",
    alignItems: "end",
  },
}));

const DescText = styled("p")(({ theme }) => ({
  fontSize: 24,
  maxWidth: 470,
  fontFamily: "Proxima Nova",
  color: theme.palette.primary.contrastText,
  marginTop: 164,
  [theme.breakpoints.down("md")]: {
    marginTop: 64,
  },
  [theme.breakpoints.up("md")]: {
    marginRight: 32,
  },
  [theme.breakpoints.up("lg")]: {
    marginRight: 128,
  },
}));

const StyledEpiphanyLogoWrapper = styled(Box)(({ theme }) => ({
  paddingRight: 72,
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  marginTop: 128,
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("lg")]: {
    paddingRight: 0,
    justifyContent: "center",
  },
}));

const StyledRevealdLogoWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 32,
  right: 48,

  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    // padding: 64
  },
}));

export default function EntryAuthorization({ children, setKeycloak, history }) {
  const theme = useTheme();
  const environment = useContext(EnvironmentContext);
  const { userInfo, authInfo, setUserInfo } = useUserInfo();
  const callQuery = useImperativeQuery(LOGIN_EMAIL_LOOKUP, {});
  const [loginLookupError, setLoginLookupError] = useState("");
  const [email, setEmail] = useState<string>("");

  const onPerformLogin = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoginLookupError("");
      if (email) {
        const domainIdentifier = getDomainFromEmail(email);
        const queryParams = { identifier: domainIdentifier };

        loginLookup$(callQuery(queryParams))
          .then((result: any) => {
            const builtInfo = { email, realm: result.realm };
            setUserInfo(builtInfo);
            if (builtInfo.realm) {
              const keycloakInstance = buildKeycloak(builtInfo, environment);
              setKeycloak(keycloakInstance);
            }
          })
          .catch((error) => {
            setLoginLookupError("Email Invalid please try again.");
            console.error("[ERROR]", error);
          });
      }
    },
    [email, callQuery, environment, setKeycloak, setUserInfo]
  );

  useEffect(() => {
    let keycloakInstance;
    if (userInfo && userInfo.email && userInfo.realm) {
      keycloakInstance = buildKeycloak(userInfo, environment);
      setKeycloak(keycloakInstance);
    }

    // this should only happen if the user is stuck in the keycloak login screen and there are still auth variables hanging
    if (!keycloakInstance && authInfo?.token) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userInfo && userInfo.email && userInfo.realm) {
    return children;
  }

  const invalidEmailFormat = !validateEmail(email);

  return (
    <Container>
      <LetfPanel>
        <Box mt={12} />
        {environment.COMPANY_LOGO === "Reveald" ? (
          <RevealdLogoSvg />
        ) : (
          environment.COMPANY_LOGO === "GoCyber" && (
            <GoCyberLogoSvg width={250} />
          )
        )}
        <Typography
          variant="h4"
          sx={{
            mt: 8,
            mb: 2,
            fontWeight: "bold",
            color: "secondary.main",
            fontFamily: "Proxima Nova",
          }}
        >
          Log in
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 2,
            color: "primary.contrastText",
            fontFamily: "Proxima Nova",
          }}
        >
          Please enter your email.
        </Typography>
        <form onSubmit={onPerformLogin}>
          <Box sx={{ mb: 1, color: "primary.contrastText" }}>Email</Box>
          <TextField
            fullWidth
            size="small"
            sx={{
              mb: 8,
              "& .MuiOutlinedInput-root": {
                bgcolor: "primary.contrastText",
                color: "primary.main",
                fontFamily: "Proxima Nova",
              },
              "& .MuiOutlinedInput-input:-webkit-autofill": {
                WebkitBoxShadow: "none",
                WebkitTextFillColor: theme.palette.common.black,
                fontFamily: "Proxima Nova",
              },
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={invalidEmailFormat || !!loginLookupError}
            helperText={
              <span style={{ fontFamily: "Proxima Nova" }}>
                {loginLookupError
                  ? loginLookupError
                  : invalidEmailFormat
                  ? "The email format is invalid, please try again"
                  : ""}
              </span>
            }
          />
          <XButton
            type="submit"
            sxProps={{
              mb: 24,
              width: "100%",
              fontSize: 16,
              fontFamily: "Proxima Nova",
              "&.MuiButton-contained": {
                bgcolor: colors.brown[300],
              },
            }}
            disabled={!email || invalidEmailFormat}
          >
            Sign in
          </XButton>
        </form>
        <Box
          sx={{
            position: "absolute",
            left: 32,
            bottom: 32,
            color: "secondary.light",
          }}
        >
          {environment.COMPANY_LOGO === "Reveald"
            ? "@ Reveald 2023"
            : environment.PRODUCT_LOGO === "GoCyber"
            ? "@ GoCyber 2024"
            : ""}
        </Box>
      </LetfPanel>
      <RightPanel>
        <Box
          sx={{
            position: "absolute",
            top: 32,
            right: 32,
            display: "flex",
            gap: 8,
          }}
        >
          {!!environment.ABOUT_LINK && (
            <Box
              component="a"
              href={environment.ABOUT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "secondary.light" }}
            >
              About
            </Box>
          )}
          {!!environment.PRIVACY_LINK && (
            <Box
              component="a"
              href={environment.PRIVACY_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "secondary.light" }}
            >
              Privacy
            </Box>
          )}
          {!!environment.TERMS_AND_CONDITIONS_LINK && (
            <Box
              component="a"
              href={environment.TERMS_AND_CONDITIONS_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "secondary.light" }}
            >
              Terms
            </Box>
          )}
          {!!environment.CONTACT_LINK && (
            <Box
              component="a"
              href={environment.CONTACT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "secondary.light" }}
            >
              Contact
            </Box>
          )}
        </Box>
        <DescText>
          Prevent Breaches with Continuous Threat Exposure Management. Transform
          SecOps into a proactive security posture.
        </DescText>
        <StyledEpiphanyLogoWrapper>
          {environment.PRODUCT_LOGO === "EPIPHANY" ? (
            <EpiphanyLoginLogo />
          ) : (
            environment.PRODUCT_LOGO === "GoCyber" && (
              <GoCyberProductLogoSvg width={533} />
            )
          )}
        </StyledEpiphanyLogoWrapper>

        <StyledRevealdLogoWrapper>
          {environment.COMPANY_LOGO === "Reveald" ? (
            <RevealdLogoBigSvg width="100%" height="100%" />
          ) : (
            environment.COMPANY_LOGO === "GoCyber" && (
              <GoCyberLogoSvg width="100%" height="100%" />
            )
          )}
        </StyledRevealdLogoWrapper>
      </RightPanel>
    </Container>
  );
}
