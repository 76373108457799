import React from "react";
import XAvatar from "../../../base-ui/XAvatar";
import { Box } from "@mui/material";

export const AvatarCell = ({
  value,
  valueFn,
}: {
  value: string;
  valueFn: (value: string) => string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <XAvatar value={valueFn(value)} />
    </Box>
  );
};
