import React, { useState, useCallback, useMemo } from "react";
import { Popover, Button, Box, ButtonGroup } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const PopoverCell = ({
  prefix,
  contents,
  onClick,
}: {
  prefix: string;
  contents: string[];
  onClick?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const onToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const onClose = useCallback((event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  }, []);

  const dropContents = useMemo(
    () => contents.filter((item) => !!item.trim()),
    [contents]
  );

  return (
    <>
      <ButtonGroup variant="contained" size="small" ref={anchorRef}>
        <Button
          variant="contained"
          size="small"
          onClick={() => (onClick ? onClick() : null)}
          sx={{
            color: "primary.contrastText",
          }}
        >
          {prefix} ({dropContents.length})
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="menu"
          onClick={onToggle}
          disabled={!dropContents.length}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popover
        open={open && dropContents.length > 0}
        anchorEl={anchorRef.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1, maxHeight: 360, overflowY: "auto" }}>
          {(dropContents || []).map((item) => (
            <div key={item}>{item}</div>
          ))}
        </Box>
      </Popover>
    </>
  );
};
