import React, { useContext } from "react";
import Box from "@mui/material/Box";
import { GoCyberLogoSvg, RevealdLogoBigSvg } from "../../components-v2/icons";
import { EnvironmentContext } from "../../app/utils/EnvironmentProvider";

const BottomLogo = ({ expanded }: { expanded: boolean }) => {
  const environment = useContext(EnvironmentContext);
  return expanded ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        p: 2,
      }}
    >
      {environment.COMPANY_LOGO === "Reveald" ? (
        <RevealdLogoBigSvg width={120} />
      ) : (
        environment.COMPANY_LOGO === "GoCyber" && <GoCyberLogoSvg width={120} />
      )}
    </Box>
  ) : (
    <></>
  );
};

export default BottomLogo;
