const shadowObj2str = (obj: any) => {
  const str = obj.value
    .reduce((cv: string, pv: any) => {
      cv += `${pv.x}px ${pv.y}px ${pv.blur}px ${pv.spread}px ${pv.color},`;
      return cv;
    }, "")
    .slice(0, -1); // remove last comma
  return str;
};

const getShadowsFromToken = (tokenData: any): string[] => {
  const shadows = ["none"];
  for (const key in tokenData) {
    if (key === "outlined") continue;
    shadows.push(shadowObj2str(tokenData[key]));
  }
  return shadows;
};

const getTypographiesFromToken = (tokenData: any) => {
  // const {
  //   fontFamilies,
  //   typography,
  //   fontWeights,
  //   lineHeights,
  //   fontSize,
  //   letterSpacing,
  // } = tokenData;
  // const typographyProperties = [
  //   "fontFamily",
  //   "fontWeight",
  //   "fontSize",
  //   "lineHeight",
  //   "letterSpacing",
  // ];
  // const typographyCategories = [
  //   "h1",
  //   "h2",
  //   "h3",
  //   "h4",
  //   "h5",
  //   "h6",
  //   "subtitle1",
  //   "subtitle2",
  //   "body1",
  //   "body2",
  //   "button",
  //   "caption",
  //   "overline",
  // ];
  // const newTypography = {};
  // for(const category of typographyCategories) {
  //   newTypography[category] = {}
  //   for(const prop of typographyProperties) {
  //     newTypography[category] = {
  //       fontFamily: "Fira Sans"
  //     }
  //   }
  // }
};

export { getShadowsFromToken, getTypographiesFromToken };
