import React from "react";
import { Box } from "@mui/material";

export const LinkCell = ({
  content,
  customContent,
  onClick,
}: {
  content: string;
  customContent?: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Box
      onClick={() => (onClick ? onClick() : null)}
      sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
    >
      {!!customContent ? customContent : content}
    </Box>
  );
};
