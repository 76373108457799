import React, { useCallback, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useMe } from "../../app/pages/auth/LoginAuth";
import useBoundStore from "../../store";
import {
  LogoutIcon,
  SupportIcon,
  SettingsIcon,
  UserProfileIcon,
  ChangelogIcon,
  DocumentationIcon,
} from "../../components-v2/icons";
import UserEditModal from "../../app/pages/home/UserSettings/SingleUser/UserEditModal";

const MenuNavAccount = () => {
  const me: any = useMe();
  const { currentUser, unsubscribeNotification } = useBoundStore(
    (state) => state
  );
  const [userInfoModal, showUserInfoModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onShowMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const onClose = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const onLogout = useCallback(() => {
    unsubscribeNotification();
    setAnchorEl(null);
    me.logout();
  }, [me, unsubscribeNotification]);

  const onViewProfile = useCallback(() => {
    setAnchorEl(null);
    showUserInfoModal(true);
  }, []);

  const onDocumentation = useCallback(() => {
    window.open(
      "https://docs.epiphanysys.com/technical-documentation/",
      "_blank"
    );
    setAnchorEl(null);
  }, []);

  const onChangelog = useCallback(() => {
    window.open(
      "https://docs.epiphanysys.com/technical-documentation/changelog",
      "_blank"
    );
    setAnchorEl(null);
  }, []);

  const onSupport = useCallback(() => {
    window.open(
      "http://reveald.com/support",
      "_blank"
    );
    setAnchorEl(null);
  }, []);

  const fullName =
    (currentUser?.firstName || "") + " " + (currentUser?.lastName || "");

  const avatarName =
    (currentUser?.firstName || "").charAt(0) +
    (currentUser?.lastName || "").charAt(0);

  return (
    <Box sx={{ ml: 2 }}>
      <IconButton
        size="small"
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        sx={{
          "&:focus": {
            outline: "none",
          },
        }}
        onClick={onShowMenu}
      >
        <Avatar
          sx={{
            width: 36,
            height: 36,
            fontWeight: "bold",
            fontSize: 16,
            boxShadow: 10,
          }}
        >
          {avatarName}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          minWidth: 400,
          "& .MuiPaper-root": {
            mt: 1,
            bgcolor: "primary.dark",
            backgroundImage: "none",
            border: "1px solid",
            borderColor: "primary.light",
          },
        }}
      >
        <MenuItem sx={{ fontSize: 13 }} onClick={onViewProfile}>
          <UserProfileIcon width={16} height={16} />
          <Box ml={1}>View Profile</Box>
        </MenuItem>
        <MenuItem disabled sx={{ fontSize: 13 }}>
          <SettingsIcon width={16} height={16} />
          <Box ml={1}>Settings</Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ fontSize: 13 }} onClick={onDocumentation}>
          <DocumentationIcon width={16} height={16} />
          <Box ml={1}>Documentation</Box>
        </MenuItem>
        <MenuItem sx={{ fontSize: 13 }} onClick={onChangelog}>
          <ChangelogIcon width={16} height={12} />
          <Box ml={1}>Changelog</Box>
        </MenuItem>
        <MenuItem sx={{ fontSize: 13 }} onClick={onSupport}>
          <SupportIcon width={16} height={16} />
          <Box ml={1}>Support</Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ fontSize: 13 }} onClick={onLogout}>
          <LogoutIcon width={16} height={16} />
          <Box ml={1}>Logout</Box>
        </MenuItem>
        <Divider />
        <Box sx={{ px: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar sx={{ mr: 2.5, fontWeight: "bold" }}>{avatarName}</Avatar>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="button"
                gutterBottom
                fontWeight="bold"
                fontSize={12}
              >
                {fullName}
              </Typography>
              <Typography variant="caption" color="secondary.light">
                {me.email}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Menu>
      {userInfoModal && (
        <UserEditModal
          open={userInfoModal}
          onClose={() => showUserInfoModal(false)}
        />
      )}
    </Box>
  );
};

export default MenuNavAccount;
