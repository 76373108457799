export const DND_TYPE = {
  NODE: "node",
};
export const MOUSE_EVENT_TYPE = {
  SELECT: "select",
  CLICK: "click",
  EXPAND: "expand",
  HOVER: "hover",
  OUT: "none",
  NONE: "none",
};
export const eiIcons = {
  "ei-eip-logomark": "\uf000",
  "ei-alpha-blue": "\uf011",
  "ei-alpha-red": "\uf012",
  "ei-link": "\uf013",
  "ei-orbital": "\uf014",
  "ei-scope": "\uf015",
  "ei-alpha-blue-simple": "\uf016",
  "ei-alpha-red-simple": "\uf017",
  "ei-link-simple": "\uf018",
  "ei-orbital-simple": "\uf019",
  "ei-scope-simple": "\uf01a",
  "ei-eip-logo": "\uf01b",
  "ei-question": "\uf01c",
  "ei-unix": "\uf01d",
  "ei-linux": "\uf01e",
  "ei-windows": "\uf01f",
  "ei-medical-device": "\uf020",
  "ei-iot": "\uf021",
  "ei-camera": "\uf022",
  "ei-router": "\uf023",
  "ei-ics": "\uf024",
  "ei-bms": "\uf025",
  "ei-server": "\uf026",
  "ei-users-cog-regular": "\uf027",
  "ei-mainframe": "\uf028",
};

export const faIcons = {
  browser: {
    code: "\uf37e",
    type: "far",
    i: "far fa-browser",
  },
  aws: {
    code: "\uf375",
    type: "fab",
    i: "fab fa-aws",
  },
  "eip-logomark": {
    code: "\uf000",
    type: "ei",
    i: "ei ei-eip-logomark",
  },
  question: {
    code: "\uf01c",
    type: "ei",
    i: "ei ei-question",
  },
  "chart-network": {
    code: "\uf78a",
    type: "fas",
    i: "fas fa-chart-network",
  },
  biohazard: {
    code: "\uf780",
    type: "fas",
    i: "fas fa-biohazard",
  },
  user: {
    code: "\uf007",
    type: "fas",
    i: "fas fa-user",
  },
  "network-wired": {
    code: "\uf6ff",
    type: "fas",
    i: "fas fa-network-wired",
  },
  users: {
    code: "\uf0c0",
    type: "fas",
    i: "fas fa-users",
  },
  "shield-virus": { code: "\ue06c", type: "fas", i: "fas fa-shield-virus" },
  gem: {
    code: "\uf3a5",
    type: "fas",
    i: "fas fa-gem",
  },
  "info-circle": {
    code: "\uf05a",
    type: "fas",
    i: "fas fa-info-circle",
  },
  "lock-open-alt": {
    code: "\uf3c2",
    type: "fas",
    i: "fas fa-lock-open-alt",
  },
  windows: {
    code: "\uf17a",
    type: "fab",
    i: "fab fa-windows",
  },
  linux: {
    code: "\uf17c",
    type: "fab",
    i: "fab fa-linux",
  },
  apple: {
    code: "\uf179",
    type: "fab",
    i: "fab fa-apple",
  },
  desktop: {
    code: "\uf108",
    type: "fas",
    i: "fas fa-desktop",
  },
  bullseye: {
    code: "\uf140",
    type: "far",
    i: "far fa-bullseye",
  },
  play: {
    code: "\uf144",
    type: "far",
    i: "far fa-play",
  },
};

export const NODE_TYPE_ICON = {
  foothold: {
    code: "\uf144",
    type: "far",
  },
  target: {
    code: "\uf140",
    type: "far",
  },
  prize: {
    code: "\uf3a5",
    type: "fa",
  },
  // user: {
  //   code: "\uf007",
  //   type: "fas",
  // },
  // group: {
  //   code: "\uf0c0",
  //   type: "fas",
  // },
};

export const IMPACT_MAP = ["CRITICAL", "HIGH", "MEDIUM", "LOW"];

export const TREND = {
  DOWN: -2,
  SLIGHT_DOWN: -1,
  NEUTRAL: 0,
  SLIGHT_UP: 1,
  UP: 2,
};

export const nones = [
  "n/a",
  "N/A",
  "N/a",
  "NA",
  "-",
  " - ",
  " ",
  "",
  "-999999",
  -999999,
  undefined,
  null,
];

export const bootstrapColors = [
  "primary", //0
  "indigo", //1
  "warning", //2
  "pink", //3
  "info", //4
  "danger", //5
  "secondary", //6
  "purple", //7
  "success", //8
  "green", //9
  "lime", //10
  "yellow", //11
  "grey", //12
  "dark-green", //13
];

export const hexBootstrapColors = [
  "#1c75c7",
  "#6929d5",
  "#d38109",
  "#fa2379",
  "#2b9fc1",
  "#ff2924",
  "#99aab3",
  "#545fa1",
  "#007979",
  "#268226",
  "#76af33",
  "#ccae00", //yellow
  "#1b5e20", //dark green
  "#81c784", // 1/2 green
];

export const TABLE_CHIP_COLORS = {
  created: bootstrapColors[0],
  open: bootstrapColors[5],
  resolved: bootstrapColors[9],
  critical: bootstrapColors[5],
  closed: bootstrapColors[9],
  high: bootstrapColors[2],
  medium: bootstrapColors[0],
  low: bootstrapColors[9],
  "risk flagged": bootstrapColors[11],
  "risk reviewed": bootstrapColors[12],
  "risk accepted": bootstrapColors[11],
  "risk remediated": bootstrapColors[9],
  node: bootstrapColors[0],
  group: bootstrapColors[8],
};

export const TABLE_ACTION_BUTTON_COLORS = {
  flag: bootstrapColors[11],
  ack: bootstrapColors[13],
  delete: bootstrapColors[5],
  ticket: bootstrapColors[4],
  remove: bootstrapColors[5],
};

export const TABLE_ACTION_ICONS = {
  flag: "fas fa-flag",
  ack: "fas fa-check",
  ticket: "fas fa-ticket-alt",
  delete: "fas fa-trash",
  remove: "fas fa-trash",
  apply: "fas fa-arrow-left",
  edit: "fas fa-edit",
};

export const COLUMN_TYPE = {
  CHECKBOX: "CHECKBOX", // for bulk action
  MULTILINE: "MULTILINE",
  MULTILINE_WITH_ARRAY: "MULTILINE WITH ARRAY",
  CHIP: "CHIP", // sortable: true
  ADDABLE_CHIPS: "ADDABLE CHIPS CELL",
  CUSTOM_CHIP: "CUSTOM CHIP",
  LINKS: "LINKS",
  LINK: "LINK", // sortable: true
  LINK_WITH_NUMBER: "LINK WITH NUMBER", //sortable: true,
  DATE: "DATE", // sortable: true
  NUMBER: "NUMBER", // sortable: true
  BOOL: "BOOL", // sortable: true
  CURRENCY: "CURRENCY", // sortable: true,
  HEADER: "HEADER",
  // EDITABLE: "EDITABLE", //sortable: true
  ACTION: "ACTION",
  BOOL_WITH_DATA: "BOOL WITH DATA",
  ICON: "ICON",
  SVG: "SVG",
  DRAG: "DRAG",
  DETAIL: "DETAIL",
  HDETAIL: "HDETAIL",
  EXPAND: "EXPAND",
  NORMAL: "NORMAL", // sortable: true
  DEVICE_LINK: "DEVICE_LINK", //show device detail modal
};

export const NODE_CONDITIONS_COLOR = {
  none: "rgb(200, 200, 200)",
  rce: hexBootstrapColors[0],
  lpe: hexBootstrapColors[1],
  exposed: hexBootstrapColors[2],
  domain: hexBootstrapColors[3],
  config: hexBootstrapColors[4],
  local: hexBootstrapColors[5],
  token: hexBootstrapColors[6],
  se: hexBootstrapColors[7],
  prize: hexBootstrapColors[8],
};

export const NODE_CONDITIONS_LABEL = {
  none: "None",
  lce: "Privilege Escalation",
  rce: "Network Exploitation",
  se: "Social Engineering",
};

export const PICKCOLORS = [  
  "#CD9575",
  "#FDD9B5",
  "#78DBE2",
  "#87A96B",
  "#FFA474",
  "#FAE7B5",
  "#9F8170",
  "#FD7C6E",
  "#000000",
  "#ACE5EE",
  "#1F75FE",
  "#A2A2D0",
  "#6699CC",
  "#0D98BA",
  "#7366BD",
  "#DE5D83",
  "#CB4154",
  "#B4674D",
  "#FF7F49",
  "#EA7E5D",
  "#B0B7C6",
  "#FFFF99",
  "#1CD3A2",
  "#FFAACC",
  "#DD4492",
  "#1DACD6",
  "#BC5D58",
  "#DD9475",
  "#9ACEEB",
  "#FFBCD9",
  "#FDDB6D",
  "#2B6CC4",
  "#EFCDB8",
  "#6E5160",
  "#CEFF1D",
  "#71BC78",
  "#6DAE81",
  "#C364C5",
  "#CC6666",
  "#E7C697",
  "#FCD975",
  "#A8E4A0",
  "#95918C",
  "#1CAC78",
  "#1164B4",
  "#F0E891",
  "#FF1DCE",
  "#B2EC5D",
  "#5D76CB",
  "#CA3767",
  "#3BB08F",
  "#FEFE22",
  "#FCB4D5",
  "#FFF44F",
  "#FFBD88",
  "#F664AF",
  "#AAF0D1",
  "#CD4A4C",
  "#EDD19C",
  "#979AAA",
  "#FF8243",
  "#C8385A",
  "#EF98AA",
  "#FDBCB4",
  "#EFDECD",
  "#1A4876",
  "#30BA8F",
  // "#C54B8C",
  // "#1974D2",
  // "#FFA343",
  // "#BAB86C",
  // "#FF7538",
  // "#FF2B2B",
  // "#F8D568",
  // "#E6A8D7",
  // "#414A4C",
  // "#FF6E4A",
  // "#1CA9C9",
  // "#FFCFAB",
  // "#C5D0E6",
  // "#FDDDE6",
  // "#158078",
  // "#FC74FD",
  // "#F78FA7",
  // "#8E4585",
  // "#7442C8",
  // "#9D81BA",
  // "#FE4EDA",
  // "#FF496C",
  // "#D68A59",
  // "#714B23",
  // "#FF48D0",
  // "#E3256B",
  // "#EE204D",
  // "#FF5349",
  // "#C0448F",
  // "#1FCECB",
  // "#7851A9",
  // "#FF9BAA",
  // "#FC2847",
  // "#76FF7A",
  // "#9FE2BF",
  // "#A5694F",
  // "#8A795D",
  // "#45CEA2",
  // "#FB7EFD",
  // "#CDC5C2",
  // "#80DAEB",
  // "#ECEABE",
  // "#FFCF48",
  // "#FD5E53",
  // "#FAA76C",
  // "#18A7B5",
  // "#EBC7DF",
  // "#FC89AC",
  // "#DBD7D2",
  // "#17806D",
  // "#DEAA88",
  // "#77DDE7",
  // "#FFFF66",
  // "#926EAE",
  // "#324AB2",
  // "#F75394",
  // "#FFA089",
  // "#8F509D",
  // "#FFFFFF",
  // "#A2ADD0",
  // "#FF43A4",
  // "#FC6C85",
  // "#CDA4DE",
  // "#FCE883",
  // "#C5E384",
  // "#FFAE42",
];
