import React, { createContext, useContext, useEffect, useState } from "react";

export interface Environment {
  EIP_GRAPH_URI: string;
  EIP_NEO4J_URI: string;
  EIP_NEO4J_USER: string;
  EIP_NEO4J_PASSWORD: string;
  EIP_NEO4J_ENCRYPTED: boolean;
  EIP_AUTH_TOKEN_TYPE: "Bearer" | "JWT";
  EIP_RISK_REPORTS_URL: string;
  EIP_KEYCLOAK_ENABLED: boolean;
  EIP_KEYCLOAK_AUTO_LOGIN_REDIRECT: boolean;
  EIP_KEYCLOAK_URL: string;
  EIP_KEYCLOAK_CLIENT_ID: string;
  EIP_KEYCLOAK_DEFAULT_REALM: string;
  EIP_KEYCLOAK_DOMAIN_REALM: boolean;
  EIP_FILE_DOWNLOAD: string;
  EIP_GRAPH_WS: string;
  appVersion: string;
  COMPANY_LOGO: string;
  PRODUCT_LOGO: string;
  ABOUT_LINK: string;
  PRIVACY_LINK: string;
  TERMS_AND_CONDITIONS_LINK: string;
  CONTACT_LINK: string;
}

export const EnvironmentContext = createContext<Partial<Environment>>({});

export default function EnvironmentProvider({
  children,
  environment,
  appVersion,
}) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (environment) {
      setLoaded(true);
    }
  }, [environment]);

  return (
    <EnvironmentContext.Provider value={{ ...environment, appVersion }}>
      {loaded ? children : <>Loading...</>}
    </EnvironmentContext.Provider>
  );
}

export function VersionComponent() {
  const { appVersion } = useContext(EnvironmentContext);
  return <div className="version-container">{appVersion}</div>;
}
