import React, { ReactNode } from "react";
import { SxProps } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

export type TXPanelProps = {
  header?: ReactNode;
  hasNoDivider?: boolean;
  children: ReactNode;
  footer?: ReactNode;
  sxProps?: SxProps;
  sxHeaderProps?: SxProps;
  sxBodyProps?: SxProps;
  sxFooterProps?: SxProps;
  onClick?: () => void;
  hoverable?: boolean;
  selected?: boolean;
};

export default function XPanel(props: TXPanelProps) {
  const {
    header,
    footer,
    sxProps,
    sxHeaderProps,
    sxBodyProps,
    sxFooterProps,
    onClick,
    hoverable,
    selected,
    children,
    hasNoDivider = false,
  } = props;
  return (
    <Box
      sx={{
        color: "primary.contrastText",
        bgcolor: "primary.main",
        borderRadius: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        ...(hoverable && {
          "&:hover": {
            // cursor: "pointer",
            opacity: 0.7,
          },
        }),
        ...(selected && {
          borderColor: "primary.light",
          border: "1px solid",
        }),
        ...(!!sxProps && { ...sxProps }),
      }}
      onClick={onClick}
    >
      {!!header && (
        <Box
          sx={{ px: 2, py: 1, ...(!!sxHeaderProps && { ...sxHeaderProps }) }}
        >
          {header}
        </Box>
      )}
      {!!header && !hasNoDivider && <Divider />}
      <Box
        sx={{
          p: 2,
          flex: 1,
          ...(!!sxBodyProps && { ...sxBodyProps }),
        }}
      >
        {children}
      </Box>
      {!!footer && <Divider />}
      {!!footer && (
        <Box
          sx={{
            p: 1,
            px: 2,
            ...(!!sxFooterProps && { ...sxFooterProps }),
          }}
        >
          {footer}
        </Box>
      )}
    </Box>
  );
}
