import { StateCreator } from "zustand";
import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";
// import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { INotification } from "./models";

const EIP_EVENT_SUBSCRIPTION = gql`
  subscription Subscription($jwtToken: String!) {
    eipEvent(jwtToken: $jwtToken) {
      data
      notificationType
      sequence
    }
  }
`;
// const MSG_PER_INFO_TYPE = {
//   TICKET_COMMENT: "Ticket comment has been added successfully.",
//   TICKET_ACTION: "Ticket action has been performed successfully.",
// };

export interface INofiticationSlice {
  subscriptionInstance: any;
  notification?: INotification;
  hasNewNotification: boolean;
  setupNofication: (
    client: ApolloClient<NormalizedCacheObject>,
    jwtToken: string
  ) => void;
  unsubscribeNotification: () => void;
}

const createNotificationSlice: StateCreator<
  INofiticationSlice,
  [],
  [],
  INofiticationSlice
> = (set, get) => ({
  subscriptionInstance: null,
  notifications: undefined,
  hasNewNotification: false,
  setupNofication: (client, jwtToken) => {
    const subscription = client
      .subscribe({
        query: EIP_EVENT_SUBSCRIPTION,
        variables: { jwtToken },
        fetchPolicy: "network-only",
      })
      .subscribe({
        next: (response: any) => {
          // console.log("Notification:", response);
          try {
            const notification: INotification = JSON.parse(
              response.data.eipEvent.data
            );

            const notificationType = response.data.eipEvent.notificationType;
            notification.internalUuid = uuidv4();
            if (notificationType === "INTERNAL") {
              // const toastMsg = MSG_PER_INFO_TYPE[notification.info_type];
              // if (toastMsg) {
              //   toast.success(toastMsg, {
              //     position: toast.POSITION.BOTTOM_RIGHT,
              //   });
              // }
              
              set({
                hasNewNotification: true,
                notification,
              });
            }
          } catch (ex) {
            console.error("[ERROR]", "notification error", ex);
            // toast.error((ex as any).toString(), {
            //   position: toast.POSITION.BOTTOM_RIGHT,
            // });
          }
        },
        error: (err) => {
          console.error("[ERROR]", "notification error", err);
          // toast.error(err, { position: toast.POSITION.BOTTOM_RIGHT });
        },
      });
    set({ subscriptionInstance: subscription });
  },
  unsubscribeNotification: () => {
    if (!!get().subscriptionInstance) {
      get().subscriptionInstance.unsubscribe();
      set({ subscriptionInstance: null });
    }
  },
});

export default createNotificationSlice;
