import React from "react";
import { Box } from "@mui/material";

export const ChipCell = ({
  value,
  valueFn,
  colorFn,
}: {
  value: string;
  valueFn?: (value: string) => React.ReactNode;
  colorFn: (value: string) => string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: colorFn(value),
          borderRadius: 4,
          height: 24,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "fit-content",
          px: 2,
        }}
      >
        {valueFn ? valueFn(value) : value}
      </Box>
    </Box>
  );
};
