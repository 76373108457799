import { useMemo } from "react";
import useResizeObserver from "use-resize-observer";

// const useResize = (predWidth?: number, predHeight?: number) => {
//   const { ref, width, height } = useResizeObserver<HTMLElement>();
//   return useMemo(
//     () => ({
//       ref,
//       width: predWidth || width || 1,
//       height: predHeight || height || 1,
//     }),
//     [ref, width, height, predWidth, predHeight]
//   );
// };

// export default useResize;


interface ResizeResult {
  ref: React.RefCallback<HTMLElement>;
  width: number;
  height: number;
}

const useResize = (predWidth?: number, predHeight?: number): ResizeResult => {
  const { ref, width, height } = useResizeObserver<HTMLElement>();

  const memoizedWidth = useMemo(() => predWidth || width || 1, [width, predWidth]);
  const memoizedHeight = useMemo(() => predHeight || height || 1, [height, predHeight]);

  return { ref, width: memoizedWidth, height: memoizedHeight };
};

export default useResize;