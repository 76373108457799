import React from "react";
import { SxProps } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

export type TXButtonProps = {
  sxProps?: SxProps;
} & Omit<ButtonProps, "sx">;

const XButton = (props: TXButtonProps) => {
  const { sxProps, children, ...rest } = props;
  return (
    <Button
      variant={rest.variant || "contained"}
      sx={{
        borderRadius: 2,
        "&.MuiButton-contained": {
          bgcolor: "primary.dark",
        },
        "&.MuiButton-outlined": {
          borderColor: "primary.dark",
          color: "primary.contrastText",
        },
        "&.Mui-disabled": {
          color: "action.focus",
        },
        ...(sxProps && { ...sxProps }),
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default XButton;
