import React, { useMemo, memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ColDef, CellClickedEvent } from "ag-grid-community";
import { Box } from "@mui/material";
import { GET_PAGE_SEARCH } from "../app/queries";
import AGInfiniteScrollTable from "./XTable/AGTable/AGInfiniteScrollTable";
import { EColumnType } from "./XTable/AGTable/interfaces";

export type THostDetailContentProps = {
  inheritedSearchText?: string;
};

const buildTableData = (data) => {
  try {
    const parsed = data.quicksearchpage[0];
    const records = JSON.parse(parsed.data);

    const rows = records.map((record) =>
      Object.values<{ key: string; value: any }>(record).reduce(
        (a: Record<string, any>, c: { key: string; value: any }) => {
          if (c.key === "ip_address" || c.key === "cves") {
            a[c.key] = (c.value || "")
              .split(",")
              .map((item) => item.trim())
              .filter((item) => !!item);
          } else a[c.key] = c.value;
          return a;
        },
        {}
      )
    );
    return {
      total: +parsed.totalPages,
      totalResults: +parsed.totalResults,
      token: parsed.token,
      rows,
    };
  } catch (err) {
    console.error("converting error", err);
    return {
      total: 0,
      token: "",
      totalResults: 0,
      rows: [],
    };
  }
};

const HostDetailContent = ({
  inheritedSearchText = "",
}: THostDetailContentProps) => {
  const history = useHistory();

  const colDefs = useMemo<ColDef<any, any>[]>(
    () => [
      {
        field: "id",
        headerName: "Id",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "payload",
        headerName: "Payload",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "hostname",
        headerName: "Hostname",
        minWidth: 200,
        resizable: true,
        cellRendererParams: {
          columnType: EColumnType.LINK,
        },
      },
      {
        field: "eipid",
        headerName: "Eipid",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "ip_address",
        headerName: "IP Address",
        minWidth: 220,
        resizable: true,
        cellRendererParams: {
          columnType: EColumnType.POPOVER,
          prefix: "IP Addresses",
        },
      },
      {
        field: "os",
        headerName: "Operating System",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "total_risks",
        headerName: "Total Risks",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "entry_points",
        headerName: "Entry Points",
        minWidth: 100,
        resizable: true,
        initialHide: true,
        cellRendererParams: {
          columnType: EColumnType.ICON,
          tooltipFn: (value) => {
            if (value && Object.entries(value).length) {
              return Object.entries(value)
                .map(([k, v]) => `${k}: ${v}`)
                .join(", ");
            }
            return "";
          },
          iconFn: () => (
            <i className="fa-regular fa-circle-exclamation fa-lg" />
          ),
        },
      },
      {
        field: "fqdn",
        headerName: "FQDN",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "sources",
        headerName: "Sources",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "domain",
        headerName: "Domain",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "device_family",
        headerName: "Device Family",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "device_type",
        headerName: "Device type",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "total_risk",
        headerName: "Total Risk",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "risk_score",
        headerName: "Risk Score",
        minWidth: 140,
        resizable: true,
        cellRendererParams: {
          columnType: EColumnType.CHML,
        },
      },
      {
        field: "in_attack",
        headerName: "In Paths",
        minWidth: 140,
        resizable: true,
        cellRendererParams: {
          columnType: EColumnType.BOOL,
        },
      },
      {
        field: "bim",
        headerName: "BIM",
        minWidth: 200,
        resizable: true,
      },
      {
        field: "primary_group",
        headerName: "Primary Group",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 140,
        resizable: true,
        initialHide: true,
      },
      {
        field: "cves",
        headerName: "CVEs",
        minWidth: 200,
        resizable: true,
        initialHide: true,
        cellRendererParams: {
          columnType: EColumnType.POPOVER,
          prefix: "CVEs",
        },
      },
      {
        field: "users",
        headerName: "Users",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "apps",
        headerName: "Apps",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
      {
        field: "has_cisa",
        headerName: "Has CISA",
        minWidth: 200,
        resizable: true,
        initialHide: true,
      },
    ],
    []
  );

  const onCellClicked = useCallback(
    (event: CellClickedEvent<any, any>) => {
      if (event.colDef.field === "hostname") {
        history.push(`/nodedetail/${event.data["eipid"]}/${event.value}`);
      }
    },
    [history]
  );

  const agTableRenderer = useMemo(
    () => (
      <Box height={600}>
        <AGInfiniteScrollTable
          initialOrderBy="hostname"
          fetchQuery={GET_PAGE_SEARCH}
          fetchPolicy="cache-first"
          columnDefs={colDefs}
          responseConvertFn={buildTableData}
          variableName="vars"
          initialSearchText={inheritedSearchText}
          onCellClicked={onCellClicked}
        />
      </Box>
    ),
    [colDefs, onCellClicked, inheritedSearchText]
  );

  return agTableRenderer;
};

export default memo(HostDetailContent);
