import React, { useRef, useEffect, memo, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { select, event } from "d3-selection";
import { pie, arc, PieArcDatum } from "d3-shape";
import useResize from "../../app/hooks/useResize";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { showTooltip, hideTooltip } from "../../utils/tooltip-util";

export type TXDonutChartProps<T> = {
  predWidth?: number;
  predHeight?: number;
  total?: number;
  hasHolding?: boolean;
  colorDict: Record<string, string>;
  data: T;
  onClickSlice?: (pair: (string | number)[]) => void;
  hideValues?: boolean;
  isOnCell?: boolean;
};

const OMIT_LEN = 12;

const XDonutChart = <T extends Record<string, number>>({
  predWidth,
  predHeight,
  total,
  data,
  hasHolding = false,
  colorDict,
  onClickSlice,
  hideValues = false,
  isOnCell = false,
}: TXDonutChartProps<T>) => {
  const theme = useTheme();
  const chartRef = useRef<SVGSVGElement>(null);
  const { ref, width, height } = useResize(predWidth, predHeight);

  const arrData = Object.entries(data);
  const radius = Math.min(width, height) * 0.45;

  const donutData = useMemo(() => {
    return pie<void, [string, number]>()
      .value((d: [string, number]) => Number(d[1]))
      .sort((a, b) => b[1] - a[1])(arrData);
  }, [arrData]);

  useEffect(() => {
    if (!chartRef.current || width < 2) return;
    select(".d3-tooltip").style("display", "none");

    const highlightDonut = (sliceName?: string) => {
      const remainingSlice = select(chartRef.current).attr("clickedSlice");
      select(chartRef.current)
        .selectAll(".slice-highlight")
        .data(donutData)
        .join(
          (enter) =>
            enter
              .append("path")
              .attr("class", "slice-highlight")
              .attr("d", (d: PieArcDatum<[string, number]>) =>
                arc<void, PieArcDatum<[string, number]>>()
                  .innerRadius(
                    radius *
                      ([sliceName, remainingSlice].includes(d.data[0])
                        ? 0.7
                        : 0.73)
                  )
                  .outerRadius(
                    radius *
                      ([sliceName, remainingSlice].includes(d.data[0])
                        ? 1.03
                        : 1)
                  )(d)
              )
              .attr(
                "fill",
                (d: PieArcDatum<[string, number]>) =>
                  colorDict[(d.data[0] || "").toLowerCase()]
              )
              .style("pointer-events", "none"),
          (update) =>
            update.attr("d", (d: PieArcDatum<[string, number]>) =>
              arc<void, PieArcDatum<[string, number]>>()
                .innerRadius(
                  radius *
                    ([sliceName, remainingSlice].includes(d.data[0])
                      ? 0.7
                      : 0.73)
                )
                .outerRadius(
                  radius *
                    ([sliceName, remainingSlice].includes(d.data[0]) ? 1.03 : 1)
                )(d)
            )
        );
    };

    select(chartRef.current)
      .select(".slice-name")
      .attr("text-anchor", "middle")
      .attr("font-size", radius * 0.16)
      .attr("dy", -radius * 0.2);
    select(chartRef.current)
      .select(".slice-value")
      .attr("text-anchor", "middle")
      .attr("font-size", radius * (isOnCell ? 0.6 : 0.4))
      .attr("dy", radius * 0.2);

    select(chartRef.current)
      .selectAll(".slice")
      .data(donutData)
      .join(
        (enter) =>
          enter
            .append("path")
            .attr("class", "slice")
            .attr("d", (d) =>
              arc<void, PieArcDatum<[string, number]>>()
                .innerRadius(radius * 0.75)
                .outerRadius(radius)(d)
            )
            .attr(
              "fill",
              (d: PieArcDatum<[string, number]>) =>
                colorDict[(d.data[0] || "").toLowerCase()]
            )
            .style(
              "cursor",
              onClickSlice && typeof onClickSlice === "function"
                ? "pointer"
                : "normal"
            )
            .on("click", (d: PieArcDatum<[string, number]>) => {
              if (isOnCell) return;
              if (onClickSlice && typeof onClickSlice === "function") {
                select(chartRef.current).attr("clickedSlice", d.data[0]);
                onClickSlice(d.data);
              }
            })
            .on("mouseover", (d: PieArcDatum<[string, number]>) => {
              highlightDonut(d.data[0]);
              const omittedLabel =
                d.data[0].length > OMIT_LEN
                  ? d.data[0].slice(0, OMIT_LEN) + "..."
                  : d.data[0];
              select(chartRef.current).select(".slice-name").text(omittedLabel);
              select(chartRef.current)
                .select(".slice-value")
                .text((d.data[1] || "").toLocaleString());
              showTooltip({
                x: event.pageX,
                y: event.pageY,
                content: d.data,
              });
            })
            .on("mouseout", () => {
              highlightDonut("");
              select(chartRef.current)
                .select(".slice-name")
                .text(total ? "TOTAL" : "");
              select(chartRef.current)
                .select(".slice-value")
                .text((total || "").toLocaleString());
              hideTooltip();
            }),
        (update) =>
          update
            .attr(
              "d",
              arc<void, PieArcDatum<[string, number]>>()
                .innerRadius(radius * 0.75)
                .outerRadius(radius)
            )
            .on("click", (d: PieArcDatum<[string, number]>) => {
              if (onClickSlice && typeof onClickSlice === "function") {
                select(chartRef.current).attr("clickedSlice", d.data[0]);
                onClickSlice(d.data);
              }
            })
      );
    if (!hasHolding) {
      select(chartRef.current).attr("clickedSlice", "");
      select(chartRef.current).selectAll(".slice-highlight").remove();
    }
  }, [
    width,
    radius,
    donutData,
    total,
    colorDict,
    hasHolding,
    isOnCell,
    onClickSlice,
  ]);

  return (
    <div ref={ref} style={{ height }}>
      {donutData.length ? (
        <svg width="100%" height="100%">
          <g
            ref={chartRef}
            transform={`translate(${width / 2}, ${height / 2})`}
          >
            {!isOnCell && !hideValues && (
              <text
                className="slice-name"
                fill={theme.palette.primary.contrastText}
              >
                {total ? "TOTAL" : ""}
              </text>
            )}
            {!hideValues && (
              <text
                className="slice-value"
                fill={theme.palette.primary.contrastText}
              >
                {(total || "").toLocaleString()}
              </text>
            )}
          </g>
        </svg>
      ) : (
        <NotInterestedIcon
          sx={{ color: "primary.main", fontSize: height, opacity: 0.5 }}
        />
      )}
    </div>
  );
};

export default memo(XDonutChart);
