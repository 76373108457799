import React from "react";
import Box from "@mui/material/Box";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

export default function SuggestItem({ inputValue, item, ...rest }) {
  const matches = match(item, inputValue);
  const parts = parse(item, matches);
  return (
    <Box component="li" {...rest}>
      <Box sx={{ py: 0 }}>
        {(parts || []).map((part, k) => (
          <Box
            component="span"
            key={k}
            sx={{
              color: part.highlight ? "secondary.contrastText" : "auto",
              fontSize: part.highlight ? 16 : 14,
              fontWeight: part.highlight ? 600 : 400,
            }}
          >
            {part.text}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
