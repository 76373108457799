import React, { memo } from "react";
import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import TuneIcon from "@mui/icons-material/Tune";
// import AddIcon from "@mui/icons-material/Add";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import GlobalSearchAutoComplete from "./GlobalSearchAutoComplete";
import Breadcrumb from "./Breadcrumb";
import MenuNavAccount from "./MenuNavAccount";
import useBoundStore from "../../store";
import layoutConfig from "../layout-config.json";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: theme.palette.primary.dark,
  boxShadow: "none",
  width: `calc(100% - ${layoutConfig.condensedDrawerWidth}px)`,
  // transition: theme.transitions.create(["width", "margin"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  ...(open && {
    marginLeft: layoutConfig.drawerWidth,
    width: `calc(100% - ${layoutConfig.drawerWidth}px)`,
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  }),
}));

type TTopNavbarProps = {
  open: boolean;
};

const TopNavbar = ({ open }: TTopNavbarProps) => {
  const currentUser = useBoundStore((state) => state.currentUser);
  
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar sx={{ shadow: "none" }}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Breadcrumb />
          <Box sx={{ flexGrow: 1 }} />
          
          <GlobalSearchAutoComplete
            disabled={Boolean(!currentUser?.systemOptions?.showSearchBar)}
          />

          <MenuNavAccount />
          {/* <Button
            variant="outlined"
            color="primary"
            startIcon={<TuneIcon />}
            sx={{ mx: 1, textTransform: "none", height: 40 }}
          >
            Customize
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ mx: 1, textTransform: "none", height: 40 }}
          >
            Create New
          </Button> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default memo(TopNavbar);
