import React, { memo } from "react";
import { Tooltip } from "@mui/material";
import { ICellRendererComp } from "ag-grid-community";
import { EColumnType } from "./interfaces";
import XDonutChart from "../../charts/XDonutChart";
import { LinkCell } from "./cell-components/LinkCell";
import { PopoverCell } from "./cell-components/PopoverCell";
import { CHMLCell } from "./cell-components/CHMLCell";
import { ChipCell } from "./cell-components/ChipCell";
import { BooleanCell } from "./cell-components/BooleanCell";
import { IconCell } from "./cell-components/IconCell";
import { DateTimeCell } from "./cell-components/DateTimeCell";
import { BallChipCell } from "./cell-components/BallChipCell";
import { AvatarCell } from "./cell-components/AvatarCell";

interface XCellRendererParams extends ICellRendererComp {
  columnType?: EColumnType;
  [key: string]: any;
}

export const XCellRenderer = memo((props: XCellRendererParams) => {
  const { value, data, columnType, colDef, ...rest } = props;
  if (value === undefined || value === null) return <></>;
  if (columnType === EColumnType.NUMBER) return value;
  if (columnType === EColumnType.BOOL) return <BooleanCell value={value} />;
  if (columnType === EColumnType.CHML) return <CHMLCell value={value} />;
  if (columnType === EColumnType.CHIP)
    return (
      <ChipCell value={value} valueFn={rest.valueFn} colorFn={rest.colorFn} />
    );
  if (columnType === EColumnType.BALLCHIP)
    return (
      <BallChipCell
        value={value}
        valueFn={rest.valueFn}
        ballColorFn={rest.ballColorFn}
      />
    );
  if (columnType === EColumnType.AVATAR)
    return <AvatarCell value={value} valueFn={rest.valueFn} />;
  if (columnType === EColumnType.DATETIME)
    return <DateTimeCell value={value} />;
  if (columnType === EColumnType.ICON)
    return (
      <IconCell
        value={value}
        rowData={data}
        iconFn={rest.iconFn}
        tooltipFn={rest.tooltipFn}
      />
    );

  if (columnType === EColumnType.LINK)
    return <LinkCell content={value} customContent={rest.customContent} />;
  if (columnType === EColumnType.POPOVER)
    return <PopoverCell prefix={rest.prefix} contents={value} />;
  if (columnType === EColumnType.DONUT)
    return (
      <XDonutChart
        predWidth={rest.predWidth}
        predHeight={rest.predHeight}
        data={value}
        colorDict={rest.colorDict(value)}
        isOnCell
      />
    );
  const v = rest.valueFn ? rest.valueFn(value, data) : value;
  const tooltip = rest.tooltipFn ? rest.tooltipFn(value, data) : v;
  if (v) {
    return (
      <Tooltip title={<div>{tooltip}</div>} arrow>
        {v}
      </Tooltip>
    );
  }
  return v;
});
