import React, { useEffect } from "react";

export default function Logout({ keycloak, logout }) {
  useEffect(() => {
    if (keycloak && logout) {
      logout();
    }
  }, [keycloak, logout]);
  return <>Loading...</>;
}
