/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import getClient from "./client";
import { StyledEngineProvider } from "@mui/material/styles";
import EnvironmentProvider from "./app/utils/EnvironmentProvider";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";

import "./index.scss"; // Standard version
import { ApolloProvider } from '@apollo/client';
import { Routes } from "./app/router/Routes";
import UserInfoProvider from "./app/pages/auth/entryPage/UserInfoContext";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
require("typeface-poppins");

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env; //mockAxios(axios); //setupAxios(axios, store);
// all the environment variables for the application are dynamically loaded from the env.json file // located in the public URL so the variables can be updated without the need of rebuilding the application.
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
const envFile = "/env.json";
const versionFile = "/version.json";
const env$ = fetch(envFile).then((response) => response.json());
const version$ = fetch(versionFile).then((response) => response.json());

Promise.all([env$, version$])
  .then((responses) => {
    return { environment: responses[0], version: responses[1] };
  })
  .then(({ environment, version }: { environment: any; version: any }) => {
    const client = getClient(
      environment.EIP_AUTH_TOKEN_TYPE,
      environment.EIP_GRAPH_URI,
      environment.EIP_GRAPH_WS
    );
    ReactDOM.render(
      <React.Fragment>
        <ApolloProvider client={client}>
          <EnvironmentProvider
            environment={environment}
            appVersion={version.APP_VERSION}
          >
            <StyledEngineProvider injectFirst>
              <UserInfoProvider>
                <BrowserRouter basename={PUBLIC_URL}>
                  <Routes apolloClient={client} />
                </BrowserRouter>
              </UserInfoProvider>
            </StyledEngineProvider>
          </EnvironmentProvider>
        </ApolloProvider>
        <ToastContainer autoClose={3000} theme="dark" limit={5} />
      </React.Fragment>,
      document.getElementById("root")
    );
  })
  .catch((error) => console.error("Application could not be loaded", error));
