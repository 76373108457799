import { ApolloClient, InMemoryCache, HttpLink, from, split } from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';
import { resolvers, typeDefs } from "./resolvers";
import { clearAllCookies } from "./app/pages/auth/WithAuth";

export default function getClient(tokenType: string, graphqlUri: string, graphqlWSUri: string) {
  // const cache = new InMemoryCache();

  // const x = localStorage.getItem("currentWidgets") || "[]";

  // try {
  //   // cache.writeData({
  //   //   data: {
  //   //     selectedBuilding: "",
  //   //     currentWidgets: JSON.parse(x) || [],
  //   //   },
  //   // });
  //   cache.writeQuery({
  //     query: gql`
  //       query GetCartItems {
  //         cartItems
  //       }
  //     `,
  //     data: {
  //       cartItems: []
  //     }
  //   });
  // } catch (e) {
  //   cache.writeData({
  //     data: {
  //       selectedBuilding: "",
  //       currentWidgets: [],
  //     },
  //   });
  // }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const authLink = setContext((_, { headers }) => {
    const localAuth =
      localStorage.getItem("persisted_state_hook:epiphany") || "{}";
    const unauthHeaders = {
      headers: {
        ...headers,
        Authorization: "",
      },
    };
    if (localAuth?.indexOf("{}") !== 0) {
      const { auth } = JSON.parse(localAuth);
      if (!auth?.authenticated) return unauthHeaders;
      // return the headers to the context so httpLink can read them
      if (auth.tokenParse) {
        const now = Math.round(Date.now() / 1000);
        if (now > auth.tokenParse.exp) {
          clearAllCookies();
          localStorage.clear();
          return unauthHeaders;
        }
      }

      return {
        headers: {
          ...headers,
          Authorization: `${tokenType} ${auth.token}`,
        },
      };
    }
    return unauthHeaders;
  });

  const httpLink = new HttpLink({
    uri: graphqlUri,
  });

  const wsLink = new WebSocketLink(
    new SubscriptionClient(graphqlWSUri, {
      reconnect: true,
      connectionCallback: (error, result) => {
        console.error("[WS Error]", error, "[WS result]", result);
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  // If you provide a link chain to ApolloClient, you
  // don't provide the `uri` option.
  const client = new ApolloClient({
    // The `from` function combines an array of individual links
    // into a link chain
    // link: authLink.concat(httpLink),
    link: from([errorLink, splitLink]),
    typeDefs,
    resolvers,
    cache: new InMemoryCache(),
  });

  return client;
}
