import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useUserInfo } from "./entryPage/UserInfoContext";

export const ORIGIN_URL = window.location.origin;

export function clearAllCookies() {
  var cookies = document.cookie.split("; ");

  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
}

const withAuth = (Component) => (props) => {  
  const { keycloak, initialized } = useKeycloak();
  const {
    userInfo,
    setAuthInfo,
    authInfo,
    setUserInfo,
    clearUserState,
  } = useUserInfo();

  const logout = () => {
    if (keycloak && keycloak.logout) {
      clearAllCookies();
      localStorage.clear();
      clearUserState();
      setTimeout(() => {
        keycloak.logout({ redirectUri: `${ORIGIN_URL}/` });
      });
    }
  }
  const updatedProps = {
    authInfo,
    setAuthInfo,
    keycloak,
    initialized,
    userInfo,
    setUserInfo,
    logout,
    ...props,
  };
  return <Component {...updatedProps}></Component>;
};

export default withAuth;
