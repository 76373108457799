import { StateCreator } from "zustand";
export interface IThemeSlice {
  currentTheme: "dark" | "light";
  switchTheme: () => void;
}

const createThemeSlice: StateCreator<IThemeSlice, [], [], IThemeSlice> = (
  set
) => ({
  currentTheme: "dark",
  switchTheme: () =>
    set((state) => ({
      currentTheme: state.currentTheme === "light" ? "dark" : "light",
    })),
});

export default createThemeSlice;
