import React from "react";
import { Box } from "@mui/material";
import XModal from "../../../../../components-v2/base-ui/XModal";
import XButton from "../../../../../components-v2/base-ui/XButton";

export const defaultPropsConfirmationDialog = {
  open: false,
  message: "",
  header: "",
  handleCancel: () => {},
  handleAccept: () => {},
};

export interface UserConfirmationDialogProps {
  header;
  message;
  open;
  handleAccept;
  handleCancel;
}

function UserConfirmationDialog({
  header,
  message,
  open,
  handleAccept,
  handleCancel,
}: UserConfirmationDialogProps) {
  return (
    <XModal
      header={header}
      open={open}
      onClose={handleCancel}
      size="sm"
      footer={
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <XButton
            variant="contained"
            onClick={handleAccept}
            sxProps={{
              mr: 1,
              "&.MuiButton-contained": {
                bgcolor: "error.main",
              },
            }}
          >
            Accept
          </XButton>
          <XButton variant="contained" onClick={handleCancel}>
            Cancel
          </XButton>
        </Box>
      }
    >
      <Box fontSize={16} textAlign="center" my={4}>
        {message}
      </Box>
    </XModal>
  );
}

export default UserConfirmationDialog;
