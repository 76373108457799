import validDomains from "../../../../validDomains.json";

function realmFromUserEmail(email: string): string {
  if (email && email.length > 0) {
    const emailDomain = email.split("@")[1] || "";
    const realm = getRealmFromDomain(emailDomain);
    return realm;
  }
  return "";
}

function getRealmFromDomain(emailDomain) {
  const domains = Object.keys(validDomains);
  // const defaultRealm = "epiphany";
  const foundDomain =
    domains.find((domain) => emailDomain.indexOf(domain) >= 0) || "";
  const foundRealm = validDomains[foundDomain];
  return foundRealm;
}

export function getDomainFromEmail(email: string) {
  if (email && email.length > 0) {
    const emailFullDomain = email.split("@")[1] || "";
    return emailFullDomain;
  }
  return "";
}

export const buildUserInfoFromEmail = (email: string) => {
  const realm = realmFromUserEmail(email);
  return { email, realm };
};
