import * as colors from "@mui/material/colors";
import { randomInRange } from "./base-utils";

export const defaultApexColors = [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#3F51B5",
  "#03A9F4",
  "#4CAF50",
  "#F9CE1D",
  "#FF9800",
];

const MUI_COLORS = [
  "red",
  "pink",
  "purple",
  "deepPurple",
  "indigo",
  "blue",
  "lightBlue",
  "cyan",
  "teal",
  "green",
  "lightGreen",
  "lime",
  "yellow",
  "amber",
  "orange",
  "deepOrange",
  "brown",
  "grey",
  "blueGrey",
];

const TONES = [
  "50",
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
  "A100",
  "A200",
  "A400",
  "A700",
];

export const IMPACT_COLOR = {
  critical: colors.red[500],
  high: colors.orange[500],
  medium: colors.yellow[500],
  low: colors.lightBlue[500],
};

const VULN_SCOPE_COLOR = {
  vulns: IMPACT_COLOR.low,
  exploits: IMPACT_COLOR.medium,
  actively_used: IMPACT_COLOR.high,
  in_paths: IMPACT_COLOR.critical,
};

export const TREND_COLOR = {
  SAME: colors.blue[500],
  DOWN: colors.green[500],
  SLIGHTLY_UP: colors.yellow[500],
  UP: colors.red[500],
};

export const CONDITION_COLOR = {
  none: colors.grey[800],
  rce: colors.lightBlue[400],
  lpe: colors.blue[800],
  exposed: colors.deepOrange[500],
  domain: colors.pink[600],
  config: colors.blue[900],
  local: colors.red["A400"],
  token: colors.green[700],
  se: colors.blueGrey[600],
  prize: colors.green["A100"],
  others: colors.brown[600],
};

export const scoreColor = (score: number): string => {
  if (score < 5) {
    return IMPACT_COLOR.low;
  } else if (score < 7) {
    return IMPACT_COLOR.medium;
  } else if (score < 9) {
    return IMPACT_COLOR.high;
  }
  return IMPACT_COLOR.critical;
};

export const coloringFn = (keys: string[]): { [key: string]: string } => {
  return keys.reduce((pv: { [keyName: string]: string }, cv: string) => {
    if (
      ["vulns", "exploits", "actively_used", "in_paths"].includes(
        cv.toLowerCase()
      )
    ) {
      pv[cv] = VULN_SCOPE_COLOR[cv.toLowerCase()];
    } else if (
      ["critical", "high", "medium", "low"].includes(cv.toLowerCase())
    ) {
      pv[cv] = IMPACT_COLOR[cv.toLowerCase()];
    } else {
      pv[cv] =
        colors[MUI_COLORS[randomInRange(0, MUI_COLORS.length - 1)]][
          TONES[randomInRange(0, TONES.length - 1)]
        ];
    }
    return pv;
  }, {});
};

export const getColorDict = (
  obj: Record<string, any>
): Record<string, string> => {
  return Object.keys(obj).reduce(
    (pv: { [keyName: string]: string }, cv: string) => {
      pv[cv.toLowerCase()] =
        colors[MUI_COLORS[randomInRange(0, MUI_COLORS.length - 1)]][
          TONES[randomInRange(0, TONES.length - 1)]
        ];

      return pv;
    },
    {}
  );
};

export const randomColor = () =>
  colors[MUI_COLORS[randomInRange(0, MUI_COLORS.length - 1)]][
    TONES[randomInRange(0, TONES.length - 1)]
  ];

export const getMuiColors = (
  obj: Record<string, any>,
  tone = 500
): Record<string, string> => {
  const count = Object.keys(obj).length;
  return Object.keys(obj).reduce(
    (pv: { [keyName: string]: string }, cv: string) => {
      pv[cv.toLowerCase()] =
        colors[MUI_COLORS[MUI_COLORS.length % count]][tone];

      return pv;
    },
    {}
  );
};

export const genLevelColors = (color: string, levels: number): string[] => {
  const baseRGB = hexToRGB(color);
  const baseHSL = rgbToHsl(baseRGB.r, baseRGB.g, baseRGB.b);

  const colors: string[] = [];
  for (let i = 1; i <= levels; i++) {
      const lightness = baseHSL.l + (i * (90 - baseHSL.l)) / (levels + 1); // Adjusted to avoid white
      const newColor = `hsl(${baseHSL.h}, ${baseHSL.s}%, ${lightness}%)`;
      colors.push(newColor);
  }
  return colors;
}

function hexToRGB(hex: string): { r: number, g: number, b: number } {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
      throw new Error('Invalid color provided');
  }
  return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
  };
}

function rgbToHsl(r: number, g: number, b: number): { h: number, s: number, l: number } {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h = 0, s, l = (max + min) / 2;

  if (max === min) {
      h = s = 0; // achromatic
  } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
  }

  return { h: h * 360, s: s * 100, l: l * 100 };
}

