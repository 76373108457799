import React, { memo, useContext } from "react";
import { Button } from "@mui/material";
import { EnvironmentContext } from "../../../app/utils/EnvironmentProvider";

const CSVDownload = ({ token }: { token?: string }) => {
  const environment = useContext(EnvironmentContext);
  const downloadUrl = `${environment.EIP_FILE_DOWNLOAD}get-csv-file?token=${token}`;

  return (
    <Button href={downloadUrl} size="small" disabled={!token}>
      <i className="fa-solid fa-file-csv fa-xl"></i>
    </Button>
  );
};

export default memo(CSVDownload);
