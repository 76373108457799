import React from "react";
import { Box } from "@mui/material";

export const NoRowsOverlay = ({
  noRowsMessage = "No Rows Available.",
}: {
  noRowsMessage?: string;
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9,
        background: "rgba(0, 0, 0, 0.5)",
      }}
    >
      {noRowsMessage}
    </Box>
  );
};
