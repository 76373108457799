import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  List,
  Collapse,
  ListItem,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
  Paper,
  Popper,
  ClickAwayListener,
  Typography,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export type TListItem = {
  label: string;
  value: string;
  icon?: JSX.Element;
  badge?: {
    background_color: string;
    foreground_color: string;
    title: string;
  };
  children?: TListItem[];
};

export type TMenuList = {
  list: TListItem[];
  selectedItem: TListItem;
  onSelectedItem?: (item: TListItem) => void;
  siderExpanded?: boolean;
};

const MenuList = ({
  list = [],
  selectedItem,
  siderExpanded = true,
  onSelectedItem,
}: TMenuList) => {
  const [expandItems, setExpandItems] = useState<string[]>([]);
  const [focusedItem, setFocusedItem] = useState<TListItem | null>(null);
  const [anchorEl, setAnchorEl] = useState<
    HTMLDivElement | HTMLLIElement | null
  >(null);

  const onItemClick = useCallback(
    (
      evt: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>,
      item: TListItem
    ) => {
      if (item.children?.length) {
        if (siderExpanded) {
          setExpandItems((prev) =>
            prev.includes(item.value)
              ? prev.filter((value) => value !== item.value)
              : [...prev, item.value]
          );
        } else {
          setFocusedItem(item);
          setAnchorEl(evt.currentTarget);
        }
      } else {
        setAnchorEl(null);
        onSelectedItem?.(item);
      }
    },
    [onSelectedItem, siderExpanded]
  );

  const renderList = useCallback(
    (listItem: TListItem) => {
      const selected =
        selectedItem.value === listItem.value ||
        (!siderExpanded &&
          !!focusedItem &&
          focusedItem.value === listItem.value);
      return (
        <ListItem
          key={listItem.value}
          disablePadding
          sx={{
            display: "block",
            bgcolor: "primary.main",
          }}
        >
          <ListItemButton
            selected={selected}
            onClick={(event) => onItemClick(event, listItem)}
            sx={{
              borderRadius: 1,
              justifyContent: siderExpanded ? "initial" : "center",
              mx: 2,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: siderExpanded ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {listItem.icon ? listItem.icon : <Box sx={{ width: 24 }} />}
            </ListItemIcon>
            <ListItemText
              primary={listItem.label}
              sx={{
                opacity: siderExpanded ? 1 : 0,
                "&>.MuiTypography-root": { fontSize: 14 },
              }}
            />
            {!!listItem.badge && siderExpanded && (
              <Chip
                size="small"
                label={listItem.badge.title}
                sx={{
                  bgcolor: listItem.badge.background_color,
                  color: listItem.badge.foreground_color,
                  fontSize: "11px",
                }}
              />
            )}
            {siderExpanded && listItem.children?.length ? (
              expandItems.includes(listItem.value) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : (
              <></>
            )}
          </ListItemButton>
          {listItem.children?.length && siderExpanded && (
            <Collapse
              key={listItem.value}
              in={expandItems.includes(listItem.value)}
              timeout="auto"
              unmountOnExit
            >
              <List>
                {listItem.children.map((child: TListItem) => renderList(child))}
              </List>
            </Collapse>
          )}
        </ListItem>
      );
    },
    [expandItems, selectedItem, siderExpanded, focusedItem, onItemClick]
  );

  useEffect(() => {
    setFocusedItem(null);
    setExpandItems([]);
  }, [siderExpanded]);

  return (
    <Box sx={{ width: "100%" }}>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Box>
          {list.length ? (
            <List>{list.map((item: TListItem) => renderList(item))}</List>
          ) : (
            <Box p={4} color="primary.light">
              No data available
            </Box>
          )}

          {!!focusedItem && (
            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement="right-start"
              sx={{
                zIndex: 1300,
                "&.MuiPopper-root": {
                  zIndex: 1300,
                },
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  marginLeft: 2,
                  backgroundImage: "none",
                  bgcolor: "primary.dark",
                  border: "1px solid",
                  borderColor: "primary.light",
                }}
              >
                <Typography
                  mx={1}
                  my={0.5}
                  variant="subtitle2"
                  fontWeight="bold"
                >
                  {focusedItem.label}
                </Typography>
                <Box
                  sx={{
                    p: 1,
                    borderTop: "1px solid",
                    borderColor: "primary.light",
                    maxHeight: 320,
                    overflowY: "auto",
                  }}
                >
                  {focusedItem!.children!.map((child: TListItem) => (
                    <MenuItem
                      key={child.value}
                      selected={selectedItem.value === child.value}
                      onClick={(event) => {
                        event.stopPropagation();
                        onItemClick(event, child);
                      }}
                      sx={{
                        borderRadius: 1,
                      }}
                    >
                      <ListItemText
                        primary={child.label}
                        sx={{
                          opacity: 1,
                          "&>.MuiTypography-root": { fontSize: 12 },
                        }}
                      />
                      {!!child.badge && (
                        <Chip
                          size="small"
                          label={child.badge.title}
                          sx={{
                            ml: 2,
                            bgcolor: child.badge.background_color,
                            color: child.badge.foreground_color,
                            fontSize: 9,
                          }}
                        />
                      )}
                    </MenuItem>
                  ))}
                </Box>
              </Paper>
            </Popper>
          )}
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default MenuList;
