import React, { memo } from "react";
import { Avatar, Tooltip } from "@mui/material";
import { getAvatar, stringToColor } from "../../app/globals/helpers";

const XAvatar = ({
  value,
  width = 32,
  height = 32,
  disabled = false,
  onClick,
}: {
  value: string | React.ReactNode;
  width?: number;
  height?: number;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const isStringValue = !!value && typeof value === "string";
  const renderer = (
    <Avatar
      sx={{
        fontSize: 15,
        fontWeight: "bold",
        width,
        height,
        bgcolor:
          disabled || !value
            ? "grey"
            : isStringValue
            ? stringToColor(value || "?")
            : "transparent",
        pointerEvents: disabled ? "none" : "auto",
        cursor: onClick ? "pointer" : "auto",
      }}
      onClick={onClick}
    >
      {isStringValue ? getAvatar(value) : value}
    </Avatar>
  );
  return (
    <Tooltip arrow title={value || "Unknown"}>
      {renderer}
    </Tooltip>
  );
};

export default memo(XAvatar);
